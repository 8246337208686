import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddressService } from 'src/app/shared/services/address.service';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/shared/services/company.service';

@Component({
  selector: 'app-modal-address',
  templateUrl: './modal-address.component.html',
  styleUrls: ['./modal-address.component.scss']
})
export class ModalAddressComponent implements OnInit {
  closeBtnName: string;
  continueBtnName: string;
  user:any;
  confirmAddress
  public checkoutForm   :  FormGroup;

  constructor(
    public fb: FormBuilder,
    private _addressService: AddressService,
    private toastr: ToastrService,
    private _companyService: CompanyService,
    public bsModalRef: BsModalRef,
    public modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.checkoutForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      lastname: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      phone: ['', [Validators.required, Validators.pattern('[0-9]+')]],
      // email: ['', [Validators.required, Validators.email]],
      address: ['', [Validators.required, Validators.maxLength(50)]],
      // country: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postal: ['', Validators.required],
      // nameTdc: ['', Validators.required],
      // idTdc: ['', Validators.required],
      // nroTdc: ['', Validators.required],
      // dateTdc: ['', Validators.required],
      // ccvTdc: ['', Validators.required]
    });
  }

  createAddress() {
    this._addressService.createAddress( this.checkoutForm.value ).subscribe( (resp) => {
      this._companyService.loadUser().subscribe( (user:any) => {
            localStorage.setItem('user', JSON.stringify(user) );
            this._companyService.user = user;
            this.toastr.success('La direccion se ha creado exitosamente');
            this.modalService.setDismissReason('added');
            this.bsModalRef.hide();
      });
    });;
}

}
