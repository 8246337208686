import { Injectable } from '@angular/core';
import { Product } from '../interfaces/product';
import { Category } from '../interfaces/category';
import { Brand } from '../interfaces/brand';

@Injectable({
    providedIn: 'root'
})
export class RootService {
    constructor() { }

    home(): string {
        return '/';
    }

    shop(): string {
        return `/productos`;
    }

    category(category: Partial<Category>): string {
        if (category.type === 'shop') {
            const basePath = this.shop();

            if ('slug' in category) {
                return `${basePath}/${category.slug}`;
            }
            if ('id' in category) {
                return `${basePath}/${category.id}`;
            }

            throw Error('Provide category with "path", "slug" or "id".');
        }
        if (category.type === 'blog') {
            return this.blog();
        }

        throw Error('Provided category with unknown type.');
    }

    product(product: Partial<Product>): string {
        const basePath = '/producto';

        if ('slug' in product) {
            return `${basePath}/${product.slug}`;
        }
        if ('id' in product) {
            return `${basePath}/${product.id}`;
        }

        throw Error('Provide product with "slug" or "id".');
    }

    brand(brand: Partial<Brand>): string {
        return '/';
    }

    cart(): string {
        return '/carrito';
    }

    notifications(): string {
        return '/notificaciones';
    }

    productNotifications(): string {
        return '/cuenta/notificaciones';
    }

    checkout(): string {
        return '/carrito/checkout';
    }

    wishlist(): string {
        return '/favoritos';
    }

    blog(): string {
        return '/blog';
    }

    post(): string {
        return `/blog/post-classic`;
    }

    login(): string {
        return '/cuenta/login';
    }

    terms(): string {
        return '/terminos';
    }

    notFound(): string {
        return `/not-found`;
    }
}
