import {Component, OnDestroy, OnInit} from '@angular/core';
import {Layout} from '../../components/products-view/products-view.component';
import {Subject, Subscription} from 'rxjs';
import {ProductService} from '../../services/products.service'
import {CompanyService} from '../../../../shared/services/company.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-page-trade-fair-comercial',
    templateUrl: './page-trade-fair-comercial.component.html',
    styleUrls: ['./page-trade-fair-comercial.component.scss']
})
export class PageTradeFairComercialComponent implements OnInit, OnDestroy {

    productSearch: any = [];
    layout: Layout = 'grid';
    grid: 'grid-3-sidebar' | 'grid-4-full' | 'grid-5-full' = 'grid-5-full';
    offcanvas: 'always' | 'mobile' = 'mobile';
    order: string = 'a-z';
    previousUrl;
    // pageActual: number = 1;
    pageSize: number = 10;
    pagination = {
        last_page: 1,
        current_page: 1,
        path: '',
        per_page: 1,
        to: 1,
        total: 1
    }

    pageActual = 1;

    productResourceTradeFair: Subscription;

    destroy$: Subject<void> = new Subject<void>();

    constructor(private _productsService: ProductService,
                public _companyService: CompanyService,
                private router: Router) {
        this.getProductsByTradeFair();
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.productResourceTradeFair.unsubscribe();
    }

    async getProductsByTradeFair() {
        await this._productsService.getProductsTradeFair();

        this.productResourceTradeFair = this._productsService.productResourceTradeFair.subscribe(products => {
            if (products) {
                const productsTradeFair: any = products;
                productsTradeFair.map((product) => {
                    product.priceWithIva = product.price + (product.price * product.iva);

                    return product;
                });

                this.productSearch = productsTradeFair;
                this.pagination = this._productsService.paginationTradeFair.meta ? this._productsService.paginationTradeFair.meta : {
                    last_page: 1,
                    current_page: 1,
                    path: ''
                }
            }
        })
    }

    // sorting type ASC / DESC / A-Z / Z-A etc.
    public onChangeSorting(val) {
        this.pageActual = 1;
        this.order = val;
        this._productsService.getProductsTradeFair(this.order, this.pageActual);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    newPage(event) {
        this.pageActual = event;
        this._productsService.getProductsTradeFair(this.order, this.pageActual);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
}
