import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { CartService } from '../../../../shared/services/cart.service';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { CartItem } from '../../../../shared/interfaces/cart-item';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { RootService } from '../../../../shared/services/root.service';
import { URL_SERVICES_IMG } from '../../../../config/config';
import Stepper from 'bs-stepper';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalCheckoutComponent } from '../../components/modal-checkout/modal-checkout.component';
import { CompanyService } from 'src/app/shared/services/company.service';
import { ModalNewBillComponent } from 'src/app/modules/account/components/modal-new-bill/modal-new-bill.component';
import { OrderService } from 'src/app/shared/services/order.service';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { ProductService } from '../../services/products.service';
import { Product } from '../../../../shared/interfaces/product';

interface Item {
    cartItem: CartItem;
    quantity: number;
    quantityControl: FormControl;
}

@Component({
    selector: 'app-cart-masive',
    templateUrl: './page-cart.component.html',
    styleUrls: ['./page-cart.component.scss']
})
export class PageCartMasiveComponent implements OnInit {
    public bsModalRef: BsModalRef;
    importProducts: Item[] = [];

    private destroy$: Subject<void> = new Subject();
    URL_SERVICES_IMG = URL_SERVICES_IMG;
    public checkoutFormBilling   :  FormGroup;
    removedItems: Item[] = [];
    items: Product[] | any[] = [];
    updating = false;
    newBilling = false;
    shippingAddress;
    public state = 'CIUDAD DE MEXICO';
    public type = 'Persona Fisica';
    public title = 'Mi Carrito';
    public innerWidth:any;
    billings: any;
    billing;
    address: any;
    bill:any;
    paymentMethods;
    paymentMethod = 1;
    pay_methods = [
        {id:'1', checked:true, description:'Deposito Bancario', name:'Depósito'},
        {id:'2', checked:false, description:'Tarjeta de Credito/Debito', name:'PayPal'},
        {id:'3', checked:false, description:'Terminal Bancaria en contra entrega', name:'Mercado Pago'},
        {id:'4', checked:false, description:'Transferencia Bancaria Directa', name:'Transferencia'}
    ];
    comment = '';
    skuNotAvailable = [];
    private stepper: Stepper;
    dataSkus = [];
    @HostListener('window:resize', ['$event'])
    onRezise(event) {
        this.innerWidth = window.innerWidth;
    }

    constructor(
        public root: RootService,
        public cart: CartService,
        private fb: FormBuilder,
        private modalService: BsModalService,
        private _companyService: CompanyService,
        private _orderService: OrderService,
        private toastr: ToastrService,
        private _productService: ProductService,
    ) {

    }

    ngOnInit(): void {
    }

    onFileChange(event: any) {
        const target: DataTransfer = <DataTransfer>(event.target);
        if (target.files.length !== 1) {
          throw new Error('Cannot use multiple files');
        }
        const reader: FileReader = new FileReader();
        reader.readAsBinaryString(target.files[0]);
        reader.onload = (e: any) => {
          /* create workbook */
          const binarystr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

          /* selected the first sheet */
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];

          /* save data */
          const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}

          const dataUnique = new Set(data);
          const results = [...dataUnique];
          results.map( (dataCod: any) => this.dataSkus.push(dataCod.CODIGO));
          this._productService.getProductsMasiva(results).subscribe( (products: any[]) => {
            const productsFilter = products.filter( (singleProduct) => singleProduct.status === 1);
            productsFilter.map( (singleProduct) => {
                const sku: any = results.find((product: any) => product.CODIGO == singleProduct.sku);
                const index = this.dataSkus.indexOf(singleProduct.sku);
                if( index >= 0 ){
                    if(sku) {
                        singleProduct.quantity = sku.PIEZAS;
                        singleProduct.iva = parseFloat(singleProduct.iva);
                    }
                    this.dataSkus.splice(index, 1);

                }
            });

            this.items = productsFilter;

          });
        };
    }

    saveComment(event){
        this.comment = event.target.value;
    }

    addToCart(): void {

        this.items.forEach( (singleProduct: any) => {
            if( singleProduct.stock < 1 || singleProduct.stock <  singleProduct.quantity){
                this.toastr.error(`No contamos con existencia suficiente para el producto ${singleProduct.name}`);
                return;
            }

            this.cart.add(singleProduct, singleProduct.quantity).subscribe({
                complete: () => {
                }
            });
        });

        this.items = [];
    }



    createOrder() {
        swal.fire({
            title: 'Atencion',
            text: 'Esta a punto de confirmar su pedido',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, confirmar'
          }).then((create) => {
            if (create.value) {
                alert('ok');
                this._orderService.createOrder(
                    this.cart.data.items,this.cart.data.total,
                    this.shippingAddress, this.billing, this.paymentMethod, this.comment
                    ).then(resp => {
                    this._companyService.loadUser().subscribe( (user:any) => {
                        localStorage.setItem('user', JSON.stringify(user) );
                        this._companyService.user = user;
                    });
                    this._orderService.nroOrder = resp.id;
                    this._orderService.payMethod = resp.pay_method;
                    this.cart.removeAllCart();
                })
            }
          });
    }
    getImage(product){
        if (product.coverImage.startsWith('http')) {
            return product.coverImage;
          }else if(product.coverImage  === 'no-photo.png'){
           return URL_SERVICES_IMG + '/' + product.coverImage;
          }
          return URL_SERVICES_IMG + '/' + product.id + '/' + product.coverImage

    }
}
