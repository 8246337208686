import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {URL_SERVICES} from '../../config/config';
import {CompanyService} from './company.service';

@Injectable({
    providedIn: 'root'
})
export class DesignService {

    constructor(private http: HttpClient,
                private _companyService: CompanyService) {
    }

    // Get Banners
    getBanners() {
        const url = URL_SERVICES + 'designs';

        return this.http.get(url);
    }

    // Get Banners List
    getBannersList(level) {
        const url = URL_SERVICES + 'get-banners/' + level;

        return this.http.get(url);
    }

    incrementCountBanner(id) {
        const url = URL_SERVICES + 'banners/' + id + '/increment/count';
        return this.http.get(url);
    }

    incrementCountSlider(id) {
        const url = URL_SERVICES + 'sliders/' + id + '/increment/count';
        return this.http.get(url);
    }

    // Get Carousel List
    getCarouselList() {
        const url = URL_SERVICES + 'brands-carousel';

        return this.http.get(url);
    }

    // Get All Carousel List
    getAllCarouselList(id) {
        const userId = this._companyService.user.id;
        const sucuralId = 1;
        const url = URL_SERVICES + 'brands-carousel-all/' + id + '/' + userId + '/' + sucuralId;

        return this.http.get(url);
    }
}
