import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { AddressService } from 'src/app/shared/services/address.service';
import { CompanyService } from 'src/app/shared/services/company.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-new-bill',
  templateUrl: './modal-new-bill.component.html',
  styleUrls: ['./modal-new-bill.component.scss']
})
export class ModalNewBillComponent implements OnInit {
  checkoutFormBilling: FormGroup;
  closeBtnName: string;
  continueBtnName: string;
  user:any;
  type = 'Persona Física'
  bill:any;
  disabled = false;
  spinner = false;

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    private fb: FormBuilder,
    private _addressService: AddressService,
    private _companyService: CompanyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.checkoutFormBilling = this.fb.group({
      razon: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      rfc: ['', [Validators.required]],
      street: ['', [Validators.required]],
      interior: [''],
      exterior: ['', Validators.required],
      colony: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      ticket: [''],
      postal: ['', Validators.required],
      type: ['', Validators.required],
    })
  }

  sendRequest() {
    this.disabled = true;
    this.spinner = true;
    const data = this.checkoutFormBilling.value;

    this._companyService.sendAddress(data).subscribe( (resp: any) => {
      this.bsModalRef.hide()
      this.disabled = false;
      this.spinner = false;
      this.toastr.success(`La solicitud se envio correctamente`);
    }, (error) => {
      this.bsModalRef.hide()
      this.disabled = false;
      this.spinner = false;
      this.toastr.error(`Error enviando solicitud`);
    });
  }

  async createBill(){
    this.checkoutFormBilling.controls['user_id'].setValue(this.user.id);

    if(this.checkoutFormBilling.valid) {
      let resp;
      let orig;
      if(this.checkoutFormBilling.controls['id'].value > 0) {
        resp = await this._addressService.updateBill(this.checkoutFormBilling.value);
        this.toastr.success('Los datos de facturación se actualizaron exitosamente');
      } else {
        resp = await this._addressService.createBill(this.checkoutFormBilling.value);
        this.toastr.success('Los datos de facturación se crearon exitosamente');
      }
      if(resp) {
        this._companyService.loadUser().subscribe( (user:any) => {
          localStorage.setItem('user', JSON.stringify(user) );
          this._companyService.user = user;
          this.modalService.setDismissReason('added');
          this.bsModalRef.hide();
    });
      }
    } else {
      this.getFormValidationErrors();

    }

  }

  getFormValidationErrors() {
    Object.keys(this.checkoutFormBilling.controls).forEach(key => {

    const controlErrors: ValidationErrors = this.checkoutFormBilling.get(key).errors;
    if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            // console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });
    }

}
