import { ModalTermComponent } from '../../components/modal-term/modal-term.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/shared/services/company.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './page-login.component.html',
    styleUrls: ['./page-login.component.scss']
})
export class PageLoginComponent implements OnInit {
    forma: FormGroup;
    formaLogin: FormGroup;
    gender = 'Hombre';
    btnDisabled = false;

    eye: string = 'fa fa-eye-slash';
    inputType: string = 'password';
    visible = false;
    ingreso = false;

    public bsModalRef: BsModalRef;

    constructor(
        private toastr: ToastrService,
        private _companyService: CompanyService,
        private router: Router,
        private modalService: BsModalService
    ) { }

    sonIguales( campo1: string, campo2: string ) {

        return ( group: FormGroup ) => {

          const pass1 = group.controls[campo1].value;
          const pass2 = group.controls[campo2].value;

          if ( pass1 === pass2 ) {
            return null;
          }

          return {
            sonIguales: true
          };

        };

      }

      ngOnInit() {

        this.forma = new FormGroup({
          name: new FormControl( null , Validators.required ),
          lastname: new FormControl( null , Validators.required ),
          birthday: new FormControl( null , Validators.required ),
          gender: new FormControl( null , Validators.required ),
          movil: new FormControl( null , Validators.required ),
          email: new FormControl( null , Validators.required ),
          password: new FormControl( null , Validators.required ),
          password2: new FormControl( null , Validators.required ),
        }, { validators: this.sonIguales( 'password', 'password2' )  } );

        this.formaLogin = new FormGroup({
          email: new FormControl( null , Validators.required ),
          password: new FormControl( null , Validators.required )
        });
      }

      registerUser() {

        if ( this.forma.invalid ) {
          if(this.forma.get('email').hasError('required')) {
            this.toastr.error(`El campo email es requerido`);
            return;
          }

          if(this.forma.get('password1').hasError('required')) {
            this.toastr.error(`La contraseña es requerida`);
            return;
          }
          if(this.forma.get('password2').hasError('required')) {
            this.toastr.error(`La contraseña es requerida`);
            return;
          }

          return;
        }

        this.btnDisabled = true;

        const role = 'Cliente';

        this._companyService.createCompany( this.forma.value, role ).subscribe( resp => {
          this.toastr.success(`El usuario se ha creado exitosamente`);
          this.login();
         }, (error: any) => {
          this.toastr.error(`Ocurrio un error creando el usuario`);
        });

      }

      login() {
        const user = {email: this.forma.value.email, password: this.forma.value.password};

        this._companyService.login( user );
      }

      loginClient() {
        const user = {email: this.formaLogin.value.email, password: this.formaLogin.value.password};        
        this._companyService.login( user );
      }

      viewPassword() {
        if (this.visible) {
          this.inputType = 'password';
          this.visible = false;
        } else {
          this.inputType = 'text';
          this.visible = true;}
      }

      disabledIngreso() {
        this.ingreso = !this.ingreso;
      }

      term() {
        this.bsModalRef = this.modalService.show(ModalTermComponent, Object.assign({}, {class:'modal-lg'}));
        this.bsModalRef.content.closeBtnName = 'Cerrar';
      }
}
