import {HostListener, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CompanyService} from './company.service';

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    pageActual: number = 1;
    pageActualPromotions: number = 1;
    pageActualNotification: number = 1;
    pageActualSearch: number = 1;
    pageActualAdvancedSearch: number = 1;
    beforeUrl: string;
    public t;

    constructor(private router: Router, private _companyService: CompanyService) {
    }

    @HostListener('document:keypress', ['$event'])
    keyPress(e) {
        if (this.router.url !== '/login') {
            this.resetTimer();
        }
    }

    @HostListener('document:mousedown', ['$event'])
    onMouseDown(e) {
        if (this.router.url !== '/login') {
            this.resetTimer();
        }
    }

    resetTimer() {
        clearTimeout(this.t);
        this.t = setTimeout(() => {
            this._companyService.logout();
        }, 5000);
    }
}
