import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { Product } from '../interfaces/product';
import { map, takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { URL_SERVICES } from '../../config/config';
import { CompanyService } from './company.service';
import { HttpClient } from '@angular/common/http';

interface WishlistData {
    items: Product[];
}

@Injectable({
    providedIn: 'root'
})
export class WishlistService implements OnDestroy {
    private data: WishlistData = {
        items: []
    };

    private destroy$: Subject<void> = new Subject();
    private itemsSubject$: BehaviorSubject<Product[]> = new BehaviorSubject([]);
    private onAddingSubject$: Subject<Product> = new Subject();

    readonly items$: Observable<Product[] | any[]> = this.itemsSubject$.pipe(takeUntil(this.destroy$));
    readonly count$: Observable<number> = this.itemsSubject$.pipe(map(items => items.length));
    readonly onAdding$: Observable<Product> = this.onAddingSubject$.asObservable();

    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any,
        private _companyService: CompanyService,
        private _http: HttpClient
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.load();
        }
    }

    add(product: Product) {
        // timer only for demo

        const user = this._companyService.user.id;
        const data = {
            user_id: user,
            product_id: product.id
        };

        let url = URL_SERVICES + 'updateFavorites';
        // url += '?token=' + this._companyService.token;

        return this._http.post( url, data ).pipe(map( () => {
            this.onAddingSubject$.next(product);
            this.data.items.push(product);
            this.itemsSubject$.next(this.data.items);
        }));
        // return timer(1000).pipe(map(() => {
        //     this.onAddingSubject$.next(product);

        //     const index = this.data.items.findIndex(item => item.id === product.id);

        //     if (index === -1) {
        //         this.data.items.push(product);
        //         this.save();
        //     }
        // }));
    }

    remove(product: Product): Observable<void> {
        // timer only for demo
        const user = this._companyService.user.id;
        const data = {
            user_id: user,
            product_id: product.id
        };

        const url = URL_SERVICES + 'updateFavorites';
        // url += '?token=' + this._companyService.token;

        return this._http.post( url, data ).pipe(map( () => {
            const index = this.data.items.findIndex(item => item.id === product.id);

            if (index !== -1) {
                this.data.items.splice(index, 1);
                this.itemsSubject$.next(this.data.items);
            }
        }));

        // return timer(1000).pipe(map(() => {
        //     const index = this.data.items.findIndex(item => item.id === product.id);

        //     if (index !== -1) {
        //         this.data.items.splice(index, 1);
        //         this.save();
        //     }
        // }));
    }

    private save(): void {
        localStorage.setItem('wishlistItems', JSON.stringify(this.data.items));

        this.itemsSubject$.next(this.data.items);
    }

    public load() {
        const items = localStorage.getItem('wishlistItems');

        if(this._companyService.user){
            const user = this._companyService.user.id;
            const sucursal = this._companyService.user.sucursal;
    
            const url = URL_SERVICES + 'getFavorites/' + sucursal + '/' + user;
            // url += '?token=' + this._companyService.token;
    
            return this._http.get( url ).subscribe( (product: Product[]) => {
                this.data.items = product;
                this.itemsSubject$.next(this.data.items);
            });
        }


        // if (items) {
        //     this.data.items = JSON.parse(items);
        //     this.itemsSubject$.next(this.data.items);
        // }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
