import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICES } from '../../config/config';

@Injectable({
  providedIn: 'root'
})

export class ConfigService {

  constructor(
    private _http: HttpClient
  ) { }

  // Get order items
  public getConfig() {
    let url = URL_SERVICES + 'config-api';

    return this._http.get( url );
  }

}
