<div class="product product--layout--{{ layout }}" *ngIf="product">
    <div class="product__content">
        <!-- .product__gallery -->
        <div class="product__gallery" *ngIf="product.pictures">
            <app-product-gallery [productLayout]="layout" [idProduct]="product.id" [discount]="product.discount" [offer]="product.temporada_oferta" [images]="product.pictures"></app-product-gallery>
        </div>
        <!-- .product__gallery / end -->
        <!-- .product__info -->
        <div class="product__info">
            <div class="product__wishlist-compare">
                <button type="button" class="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip" data-placement="right" title="Favoritos" appClick (click)="addToWishlist()" [ngClass]="{'btn-loading': addingToWishlist}">
                    <app-icon name="wishlist-16" size="16"></app-icon>
                </button>
                <button type="button" class="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip" data-placement="right" title="Comparar" appClick (click)="addToCompare()" [ngClass]="{'btn-loading': addingToCompare}">
                    <app-icon name="compare-16" size="16"></app-icon>
                </button>
            </div>
            <h1 class="product__name mb-2">{{ product.name }}</h1>
            <!-- <div class="">
                <span style="color: #1fd468 !important;">Código:</span> {{ product.sku }}
            </div> -->
            <div class="product__description">
                <h3>Sustancia(s)</h3>
                <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ornare, mi in ornare elementum, libero nibh lacinia urna, quis convallis lorem erat at purus. Maecenas eu varius nisi. -->
                {{product.description}}
            </div>
            <div class="mt-3">
                <span class="font-weight-bold" style="color: #1fd468 !important;">Piezas por caja:</span> {{ product.piezas_bulto_original }}
            </div>
            <div class="">
                <span class="font-weight-bold" style="color: #1fd468 !important;">Laboratorio:</span> {{ product.department }}
            </div>
            <div class="" *ngIf="product.concentracion">
                <span class="font-weight-bold" style="color: #1fd468 !important;">Concentración:</span> {{ product.concentracion }}
            </div>
            <div class="" *ngIf="product.presentacion">
                <span class="font-weight-bold" style="color: #1fd468 !important;">Presentación:</span> {{ product.presentacion }}
            </div>
            <div class="" *ngIf="product.contenido">
                <span class="font-weight-bold" style="color: #1fd468 !important;">Contenido:</span> {{ product.contenido }}
            </div>
            <div class="" *ngIf="product.sustancia_activa_1">
                <span class="font-weight-bold" style="color: #1fd468 !important;">Sustancia Activa 1:</span> {{ product.sustancia_activa_1 }}
            </div>
            <div class="" *ngIf="product.sustancia_activa_2">
                <span class="font-weight-bold" style="color: #1fd468 !important;">Sustancia Activa 2:</span> {{ product.sustancia_activa_2 }}
            </div>
            <div class="" *ngIf="product.sustancia_activa_3">
                <span class="font-weight-bold" style="color: #1fd468 !important;">Sustancia Activa 3 o más:</span> {{ product.sustancia_activa_3 }}
            </div>
            <div class="">
                <span class="font-weight-bold" style="color: #1fd468 !important;">Precio Venta Público:</span> {{ +product.price_sale + (+product.price_sale * +product.iva)|currencyFormat }} MXN
            </div>
            <div class="">
                <span class="font-weight-bold" style="color: #1fd468 !important;">Descuento:</span> {{ product.discount| number:'1.2-2' }}%
            </div>
            <div class="">
                <span class="font-weight-bold" style="color: #1fd468 !important;">IVA:</span> {{ product.iva * 100 }}%
            </div>
            <ul class="product__meta mb-3" *ngIf="product.stock > 0">
                <li class="product__meta-availability">
                    <span class="font-weight-bold text-height-18">Inventario: </span>
                    <span class="text-success font-weight-bold text-height-18" *ngIf="product.stock > 0 && product.stock >= 50">Disponible</span>
                    <span class="text-warning font-weight-bold text-height-18" *ngIf="product.stock > 0 && product.stock < 50">Disponible</span>
                    <span class="text-danger font-weight-bold text-height-18" *ngIf="product.stock <= 0">No Disponible</span>
                </li>
                <li><span class="font-weight-bold text-height-16">Código: </span> <span class="font-weight-bold text-height-16">{{ product.sku }}</span></li>
            </ul>

            <ul class="product__meta mb-3"  *ngIf="product.stock <= 0">
                <li class="product__meta-availability">
                    <span class="font-weight-bold text-height-18">Inventario: </span>
                    <span class="text-success font-weight-bold text-height-18" *ngIf="product.stock > 0 && product.stock >= 50">Disponible</span>
                    <span class="text-warning font-weight-bold text-height-18" *ngIf="product.stock > 0 && product.stock < 50">Disponible</span>
                    <span class="text-danger font-weight-bold text-height-18" *ngIf="product.stock <= 0">No Disponible</span>
                    <br>
                    <span class="font-weight-bold text-height-16">Código: </span> <span class="font-weight-bold text-height-16">{{ product.sku }}</span>
                </li>
                <li *ngIf="product.stock <= 0 || product.notify">
                    <button type="button" class="btn btn-secondary"
                            [ngClass]="product.notify ? 'text-danger' : 'text-success'"
                            [title]="product.notify ? 'No notificar producto cuando tenga Existencia' : 'Notificar producto cuando tenga Existencia'"
                            appClick (click)="notifyChangeProduct(!product.notify)">
                        <i class="fa fa-bell" [ngClass]="product.notify ? 'text-danger' : 'text-success'"></i>
                        {{ product.notify ? 'No Notificar Disponibilidad' : 'Notificar Disponibilidad' }}
                    </button>
                </li>
            </ul>
            <ul class="product__meta"></ul>
        </div>
        <!-- .product__info / end -->
        <!-- .product__sidebar -->
        <div class="product__sidebar">
            <div class="product__availability">
                <span class="font-weight-bold">Inventario: </span>
                <span class="item-enabled text-success font-weight-bold" *ngIf="product.stock > 0 && product.stock >= 50">Disponible</span>
                <span class="item-enabled text-warning font-weight-bold" *ngIf="product.stock > 0 && product.stock < 50">Disponible</span>
                <span class="item-enabled text-danger font-weight-bold" *ngIf="product.stock <= 0">No Disponible</span>
                <!-- <span class="text-success font-weight-bold" *ngIf="product.stock > 0">Disponible</span>
                <span class="text-danger font-weight-bold" *ngIf="product.stock <= 0">No Disponible</span> -->
            </div>
            <div class="product__prices mb-1 mt-1">
                <ng-container *ngIf="!product.compareAtPrice">
                    Precio: {{ product.priceWithIva|currencyFormat }} MXN
                </ng-container>
            </div>
            <div class="product-card__actions mb-2" *ngIf="product.temporada_oferta === 'S'">
                <div class="mt-2">
                    A partir de:
                    <span class="text-success">{{ product.pieces_offer }} Pieza<span *ngIf="product.pieces_offer > 1">s</span></span>
                </div>
                <div class="product-card__prices mt-1" style="color: #282c2a; font-size:20px">
                    <ng-container>
                        <span class="product-card__new-price">{{ product.price_offer|currencyFormat }} MXN</span>{{ ' ' }}
                    </ng-container>
                </div>
            </div>
            <!-- .product__options -->
            <form class="product__options">
                <div class="form-group product__option">
                    <label class="product__option-label">Cantidad</label>
                    <div class="product__actions">
                        <div class="product__actions-item">
                            <app-input-number aria-label="Quantity" class="product__quantity" size="lg" [pieces]="product.pieces_offer" [min]="1" [formControl]="quantity"></app-input-number>
                        </div>
                        <div class="product__actions-item product__actions-item--addtocart">
                            <button type="button" class="btn btn-primary btn-lg" [ngClass]="{'btn-loading': addingToCart}" appClick (click)="addToCart()">Agregar al carrito</button>
                        </div>
                    </div>
                </div>
            </form>
            <div class="cursor mt-3" (click)="addToWishlist()">
                <i class="fa fa-heart text-danger"></i>
                <span class="ml-2">Agregar a favoritos</span>
            </div>
            <!-- .product__options / end -->
        </div>
        <!-- .product__end -->
        <div class="product__footer">
        </div>
    </div>
</div>
