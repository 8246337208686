import {Component, EventEmitter, Output} from '@angular/core';
import {RootService} from '../../../../shared/services/root.service';
import {NotificationService} from '../../../../shared/services/notification.service';
import {ProductService} from '../../../shop/services/products.service';
import { URL_SERVICES_IMG } from '../../../../config/config';

@Component({
    selector: 'app-drop-product-notification',
    templateUrl: './drop-product-notification.component.html',
    styleUrls: ['./drop-product-notification.component.scss']
})
export class DropProductNotificationComponent {

    @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();
    URL_SERVICES_IMG = URL_SERVICES_IMG;
    constructor(public root: RootService,
                public notification: NotificationService,
                private _productService: ProductService) {
    }

    markReceived(notification) {
        this._productService.changeStatusNotification(notification.id).subscribe(() => {
            this.notification.load();
        });
    }
    getImage(item){
        if (item.coverImage.startsWith('http')) {
            return item.coverImage;
          }else if(item.coverImage  === 'no-photo.png'){
           return URL_SERVICES_IMG + '/' + item.coverImage;
          }
          return URL_SERVICES_IMG + '/' + item.product_id + '/' + item.coverImage

    }
}
