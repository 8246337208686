<ng-container>
    <ul class="nav-links__list">
        <li *ngFor="let item of items" class="nav-links__item" [ngClass]="{
                'nav-links__item--has-submenu': item.menu,
                'nav-links__item--hover': hoveredItem === item
            }" (mouseenter)="onItemMouseEnter(item)" (mouseleave)="onItemMouseLeave(item)" (appOutsideTouchClick)="onOutsideTouchClick(item)" #itemElement>

            <ng-container *ngIf="item.label !== 'OFERTAS'">
                <ng-template #link>
                    <div class="nav-links__item-body">
                        <i *ngIf="item.icon" [class]=item.icon></i> {{ item.label }}
                        <app-icon *ngIf="item.menu" class="nav-links__item-arrow" name="arrow-rounded-down-9x6" size="9x6"></app-icon>
                    </div>
                </ng-template>

                <a *ngIf="!item.external" class="nav-links__item-link" [routerLink]="item.url" [target]="item.target" (appTouchClick)="onTouchClick($event, item)">
                    <ng-container [ngTemplateOutlet]="link"></ng-container>
                </a>
                <a *ngIf="item.external" class="nav-links__item-link" [href]="item.url" [target]="item.target" (appTouchClick)="onTouchClick($event, item)">
                    <ng-container [ngTemplateOutlet]="link"></ng-container>
                </a>

                <div *ngIf="item.menu && item.menu.type == 'menu'" class="nav-links__submenu nav-links__submenu--type--menu" [ngClass]="{'nav-links__submenu--open': hoveredItem === item}" #submenuElement>
                    <app-header-menu [items]="item.menu.items" (itemClick)="onSubItemClick()"></app-header-menu>
                </div>
                <div *ngIf="item.menu && item.menu.type == 'megamenu'" class="nav-links__submenu nav-links__submenu--type--megamenu nav-links__submenu--size--{{ item.menu.size }}" [ngClass]="{'nav-links__submenu--open': hoveredItem === item}" #submenuElement>
                    <app-header-megamenu [menu]="item.menu" (itemClick)="onSubItemClick()"></app-header-megamenu>
                </div>
            </ng-container>
            <ng-container *ngIf="item.label === 'OFERTAS'">
                <ng-container *ngIf="_userService.user.discount === 'level_1' || 
                    _userService.user.discount === 'level_2' ||
                    _userService.user.discount === 'level_3' ||
                    _userService.user.discount === 'level_4' ||
                    _userService.user.discount === 'level_5' ||
                    _userService.user.discount === 'level_6' ||
                    _userService.user.discount === 'level_7' ||
                    _userService.user.discount === 'level_8' ||
                    _userService.user.discount === 'level_9' ||
                    _userService.user.discount === 'level_10' ||
                    _userService.user.discount === 'level_11' ||
                    _userService.user.discount === 'level_12'">
                    <ng-template #link>
                        <div class="nav-links__item-body">
                            <i *ngIf="item.icon" [class]=item.icon></i> {{ item.label }}
                            <app-icon *ngIf="item.menu" class="nav-links__item-arrow" name="arrow-rounded-down-9x6" size="9x6"></app-icon>
                        </div>
                    </ng-template>

                    <a *ngIf="!item.external" class="nav-links__item-link" [routerLink]="item.url" [target]="item.target" (appTouchClick)="onTouchClick($event, item)">
                        <ng-container [ngTemplateOutlet]="link"></ng-container>
                    </a>
                    <a *ngIf="item.external" class="nav-links__item-link" [href]="item.url" [target]="item.target" (appTouchClick)="onTouchClick($event, item)">
                        <ng-container [ngTemplateOutlet]="link"></ng-container>
                    </a>

                    <div *ngIf="item.menu && item.menu.type == 'menu'" class="nav-links__submenu nav-links__submenu--type--menu" [ngClass]="{'nav-links__submenu--open': hoveredItem === item}" #submenuElement>
                        <app-header-menu [items]="item.menu.items" (itemClick)="onSubItemClick()"></app-header-menu>
                    </div>
                    <div *ngIf="item.menu && item.menu.type == 'megamenu'" class="nav-links__submenu nav-links__submenu--type--megamenu nav-links__submenu--size--{{ item.menu.size }}" [ngClass]="{'nav-links__submenu--open': hoveredItem === item}" #submenuElement>
                        <app-header-megamenu [menu]="item.menu" (itemClick)="onSubItemClick()"></app-header-megamenu>
                    </div>
                </ng-container>
            </ng-container>
        </li>
    </ul>
</ng-container>