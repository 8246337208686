<div class="site-footer__widget footer-links">
    <!-- <img src="assets/brand/logofooter.png" alt="" width="180px"> -->

    <!-- <div class="footer-contacts__text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in feugiat lorem. Pellentque ac placerat tellus.
    </div> -->

    <h5 class="footer-links__title">Contacto</h5>

    <ul class="footer-contacts__contacts">
        <li>
            <!-- <i class="footer-contacts__icon fas fa-globe-americas"></i> -->
            Privada Benito Juárez 1505 San Bernardino Tlaxcalancingo San Andrés Cholula, Puebla
        </li>
        <li>
            <!-- <i class="footer-contacts__icon far fa-envelope"></i>  -->
            <a href="mailto:info@farmater.com.mx">info@farmater.com.mx</a>
        </li>
        <li>
            <!-- <i class="footer-contacts__icon fas fa-mobile-alt"></i>  -->
            2224820174 / 2224046256 / 2224820175
        </li>
        <!-- <li><i class="footer-contacts__icon fas fa-globe-americas"></i> {{ store.address }}</li>
        <li><i class="footer-contacts__icon far fa-envelope"></i> {{ store.email }}</li>
        <li><i class="footer-contacts__icon fas fa-mobile-alt"></i> {{ store.phone }}</li> -->
        <!-- <li><i class="footer-contacts__icon far fa-clock"></i> {{ store.hours }}</li> -->
    </ul>
</div>