<div class="modal-header">    
    <h4 class="modal-title pull-left">Términos y Condiciones</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="postcontent">
        <div id="faqs" class="faqs">
            <div id="faqs-list" class="fancy-title title-bottom-border">
                <h3 class="text-uppercase text-center text-sm-left">Aviso de privacidad</h3>
            </div>

            <p class="text-justify">De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, <strong>Farmacéuticos Tenorio S.A. de C.V.</strong>, pone a su disposición el siguiente aviso de privacidad. </p>

            <p class="text-justify"><strong>Farmacéuticos Tenorio S.A. de C.V.</strong> es responsable del uso y protección de sus datos personales, en este sentido y atendiendo las obligaciones legales establecidas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, a través de este instrumento se informa a los titulares de los datos, la información que de ellos se recaba y los fines que se le darán a dicha información.</p>

            <p class="text-justify">Además de lo anterior, informamos a usted que <strong>Farmacéuticos Tenorio, S.A. de C.V.</strong>, tiene su domicilio ubicado en: </p>

            <p class="text-justify">Privada Benito Juárez No. 1505, San Bernardino Tlaxcalancingo, CP. 72820, San Andrés Cholula Puebla. </p>

            <p class="text-justify">Los datos personales que recabamos de usted serán utilizados para las siguientes finalidades, las cuales son necesarias para concretar nuestra relación con usted, así como para atender los servicios y/o pedidos que solicite: </p>

            <p class="text-justify">Los Datos Personales y Datos Personales Sensibles de Clientes y prospectos serán tratados para en su caso, entablar relaciones comerciales, celebrar acuerdos de convenios, contratos o documentación diversa entre el TITULAR y Farmacéuticos Tenorio S.A. de C.V.; cumplir obligaciones y normatividad aplicable; dar respuesta a entidades o autoridades financieras y judiciales, con la finalidad de cumplir nuestras obligaciones derivadas de leyes, obligaciones tributarias, así como el cumplimiento de notificaciones o requerimientos oficiales o de carácter judicial; prevención de fraudes y operaciones con recursos de procedencia ilícita; estudios estadísticos; evaluar la calidad de nuestros productos y servicios; así como acceso y servicios a través de medios electrónicos. </p>

            <ul class="ul-privacy">
                <li>
                    <p>Los Datos Personales y Datos Personales Sensibles de proveedores o prestadores de bienes y/o servicio (incluye promotores y fuerza de ventas en general) serán tratados para los fines vinculados con la relación comercial y contractual entre “Farmacéuticos Tenorio S.A. de C.V.” con el “TITULAR”, así como para evaluar la calidad de nuestros productos y servicios.</p>

                    <p>Para las finalidades señaladas en el presente aviso de privacidad “Farmacéuticos Tenorio S.A. de C.V.” podrá recabar sus datos personales de distintas formas: cuando usted nos los proporciona directamente (datos de identificación, de contacto o mercadológicos); cuando usted visita nuestro sitio de internet (<a href="https://www.farmater.com.mx"><span>www.farmater.com.mx</span></a>) o utiliza nuestros servicios en línea.</p>

                    <p>Los datos que obtenemos por los medios antes mencionados pueden ser entre otros: su nombre, colonia, teléfono, código postal, estado, fecha de nacimiento, género, correo electrónico (e-mail), RFC, financieros y/o patrimoniales, fotografías. </p>
                </li>
            </ul>

            <div class="divider"><i class="icon-circle"></i></div>

            <p class="text-justify">Por otra parte, informamos a usted, que los datos personales no serán compartidos con ninguna autoridad, empresa, organización o personas distintas a nosotros y serán utilizados exclusivamente para los fines señalados. </p>

            <p class="text-justify">Usted tiene en todo momento el derecho de conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (acceso).  Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (rectificación); de igual manera, tiene derecho a que su información se elimine de nuestros registros o bases de datos cuando considere que la misma no esta siendo utilizada adecuadamente (cancelación); así como también a oponerse al uso de sus datos personales para fines específicos (oposición). Estos derechos se conocen como derechos ARCO. </p>

            <p class="text-justify">Para el ejercicio de cualquiera de los derechos ARCO, se deberá presentar la solicitud respectiva a través del siguiente correo electrónico: </p>

            <ul class="ul-privacy">
                <li>
                    <a href="mailto:servicioaclientes@farmater.com.mx"><span>servicioaclientes@farmater.com.mx</span></a>
                </li>
            </ul>

            <p class="text-justify">Lo anterior también servirá para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO. </p>

            <p class="text-justify">En todo caso la respuesta a la solicitud se dará en el siguiente plazo: 3 días hábiles. </p>

            <p class="text-justify">Los datos de contacto de la persona o departamento de datos personales, que está a cargo de dar trámite a las solicitudes de derechos ARCO, son los siguientes: </p>

            <ol type="A">
                <li>
                    <p>Nombre del responsable: María de los Angeles Vazquez Delgado</p>
                </li>
                <li>
                    <p>Domicilio: Privada Benito Juárez #1505, San Bernardino Tlaxcalancingo, CP 72820 </p>
                </li>
                <li>
                    <p>Teléfono: 800 570 1174</p>
                </li>
            </ol>

            <p class="text-justify">Cabe mencionar, que en cualquier momento usted puede revocar su consentimiento del uso de sus datos personales. Del mismo modo, usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales, expresando las causas q motiven la eliminación. Y en el derecho de oposición debe señalar los motivos que justifican se finalice el tratamiento de los datos personales y el daño o perjuicio que le causaría, o bien, si la oposición es parcial, debe indicar las finalidades específicas con las que no se está de acuerdo, siempre que no sea un requisito obligatorio.</p>

            <p class="text-justify">Asimismo, usted deberá considerar que para ciertos fines la revocación de su consentimiento implicará que no podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.</p>

            <p class="text-justify">Para revocar el consentimiento que usted otorga en este acto o para limitar su divulgación, se deberá presentar la solicitud respectiva a través de la siguiente dirección electrónica:</p>

            <ul class="ul-privacy">
                <li>
                    <a href="mailto:servicioaclientes@farmater.com.mx"><span>servicioaclientes@farmater.com.mx</span></a>
                </li>
            </ul>

            <p class="text-justify">Del mismo modo, podrá solicitar la información para conocer el procedimiento y requisitos para la revocación del consentimiento, así como limitar el uso y divulgación de su información personal. </p>

            <p class="text-justify">En cualquier caso, la respuesta a las peticiones se dará a conocer el siguiente plazo:</p>

            <p class="text-justify">3 días hábiles.</p>

            <p class="text-justify">En caso de realizar alguna modificación al Aviso de Privacidad, nos comprometemos a mantenerlo informado, sin embargo, usted puede solicitar información a través del portal:</p>

            <ul class="ul-privacy">
                <li>
                    <a href="https://www.farmater.com.mx"><span>www.farmater.com.mx</span></a>
                </li>
            </ul>
            <div class="divider divider-right"><a href="#" data-scrollto="#faqs-list"><i class="icon-chevron-up"></i></a></div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-ligth" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
</div>