import { NgModule } from '@angular/core';
import { Routes, RouterModule, Data, ResolveData } from '@angular/router';
import { PageCategoryComponent } from './pages/page-category/page-category.component';
import { PageTrainingComponent } from './pages/page-training/page-training.component';
import { PageCartComponent } from './pages/page-cart/page-cart.component';
import { PageWishlistComponent } from './pages/page-wishlist/page-wishlist.component';
import { PageCheckoutComponent } from './pages/page-checkout/page-checkout.component';
import { PageCompareComponent } from './pages/page-compare/page-compare.component';
import { PageTrackOrderComponent } from './pages/page-track-order/page-track-order.component';
import { CheckoutGuard } from './guards/checkout.guard';
import { PageProductComponent } from './pages/page-product/page-product.component';
import { ProductsListResolverService } from './resolvers/products-list-resolver.service';
import { CategoryResolverService } from './resolvers/category-resolver.service';
import { ProductResolverService } from './resolvers/product-resolver.service';
import { PageOrderSuccessComponent } from './pages/page-order-success/page-order-success.component';
import { PageSearchComponent } from './pages/page-search/page-search.component';
import { PageCartMasiveComponent } from './pages/page-cart-masive/page-cart.component';
import { PagePromotionComponent } from './pages/page-promotions/page-promotion.component';
import { PageFeriaComponent } from './pages/page-feria/page-feria.component';
import {PageNotificationComponent} from './pages/page-notification/page-notification.component';
import {PageAdvancedSearchComponent} from './pages/page-advanced-search/page-advanced-search.component';
import {PageTradeFairComercialComponent} from './pages/page-trade-fair-comercial/page-trade-fair-comercial.component';
import {PageNewProductsComponentComponent} from './pages/page-new-products-component/page-new-products-component.component';

const categoryPageData: Data = {
    // Number of products per row. Possible values: 3, 4, 5.
    columns: 3,
    // Shop view mode by default. Possible values: 'grid', 'grid-with-features', 'list'.
    viewMode: 'grid',
    // Sidebar position. Possible values: 'start', 'end'.
    // It does not matter if the value of the 'columns' parameter is not 3.
    // For LTR scripts "start" is "left" and "end" is "right".
    sidebarPosition: 'start'
};

const categoryPageResolvers: ResolveData = {
    category: CategoryResolverService,
    products: ProductsListResolverService
};

const routes: Routes = [
    {
        path: 'catalog',
        component: PageCategoryComponent,
        data: categoryPageData,
        resolve: categoryPageResolvers,
    },
    {
        path: 'catalog/:slug',
        component: PageCategoryComponent,
    },
    {
        path: 'search',
        component: PageSearchComponent
    },
    {
        path: 'advanced-search',
        component: PageAdvancedSearchComponent
    },
    {
        path: 'promociones',
        component: PagePromotionComponent
    },
    {
        path: 'feria',
        component: PageFeriaComponent
    },
    {
        path: 'oc-caducidad',
        component: PageTradeFairComercialComponent
    },
    {
        path: 'productos-nuevos',
        component: PageNewProductsComponentComponent
    },
    {
        path: 'productos',
        component: PageCategoryComponent,
        data: categoryPageData,
        resolve: categoryPageResolvers,
    },
    {
        path: 'productos/:categorySlug',
        component: PageCategoryComponent
    },
    {
        path: 'capacitaciones',
        component: PageTrainingComponent,
        data: categoryPageData,
        resolve: categoryPageResolvers
    },
    {
        path: 'producto/:productSlug',
        component: PageProductComponent,
        data: {
            // Product page layout. Possible values: 'standard', 'columnar', 'sidebar'.
            layout: 'standard',
            // Sidebar position. Possible values: 'start', 'end'.
            // It does not matter if the value of the 'layout' parameter is not 'sidebar'.
            // For LTR scripts "start" is "left" and "end" is "right".
            sidebarPosition: 'start'
        },
        resolve: {
            // product: ProductResolverService
        },
    },
    {
        path: 'compra-masiva',
        pathMatch: 'full',
        component: PageCartMasiveComponent
    },
    {
        path: 'carrito',
        pathMatch: 'full',
        component: PageCartComponent
    },
    {
        path: 'carritp/checkout',
        component: PageCheckoutComponent,
        canActivate: [CheckoutGuard],
    },
    {
        path: 'carrito/checkout/exito',
        component: PageOrderSuccessComponent,
    },
    {
        path: 'favoritos',
        component: PageWishlistComponent
    },
    {
        path: 'notificaciones',
        component: PageNotificationComponent
    },
    {
        path: 'compare',
        component: PageCompareComponent
    },
    {
        path: 'track-order',
        component: PageTrackOrderComponent
    },

    // --- START ---
    // The following routes are only needed to demonstrate possible layouts of some pages. You can delete them.
    {
        path: 'category-grid-4-columns-full',
        component: PageCategoryComponent,
        data: {
            columns: 4,
            viewMode: 'grid',
            categorySlug: 'power-tools',
        },
        resolve: {
            category: CategoryResolverService,
            products: ProductsListResolverService
        },
    },
    {
        path: 'category-grid-5-columns-full',
        component: PageCategoryComponent,
        data: {
            columns: 5,
            viewMode: 'grid',
            categorySlug: 'power-tools',
        },
        resolve: {
            category: CategoryResolverService,
            products: ProductsListResolverService
        },
    },
    {
        path: 'category-list',
        component: PageCategoryComponent,
        data: {
            columns: 3,
            viewMode: 'list',
            sidebarPosition: 'start',
            categorySlug: 'power-tools',
        },
        resolve: {
            category: CategoryResolverService,
            products: ProductsListResolverService
        },
    },
    {
        path: 'category-right-sidebar',
        component: PageCategoryComponent,
        data: {
            columns: 3,
            viewMode: 'grid',
            sidebarPosition: 'end',
            categorySlug: 'power-tools',
        },
        resolve: {
            category: CategoryResolverService,
            products: ProductsListResolverService
        },
    },
    {
        path: 'product-standard',
        component: PageProductComponent,
        data: {
            layout: 'standard',
            sidebarPosition: 'start',
            productSlug: 'brandix-screwdriver-screw1500acc',
        },
        resolve: {
            product: ProductResolverService
        },
    },
    {
        path: 'product-columnar',
        component: PageProductComponent,
        data: {
            layout: 'columnar',
            productSlug: 'brandix-screwdriver-screw1500acc',
        },
        resolve: {
            product: ProductResolverService
        },
    },
    {
        path: 'product-sidebar',
        component: PageProductComponent,
        data: {
            layout: 'sidebar',
            sidebarPosition: 'start',
            productSlug: 'brandix-screwdriver-screw1500acc',
        },
        resolve: {
            product: ProductResolverService
        },
    },
    // --- END ---
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ShopRoutingModule { }
