import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ShopService } from 'src/app/shared/api/shop.service';
import { Layout } from '../../components/products-view/products-view.component';
import { ProductService } from '../../services/products.service';
import { Subject, Subscription } from 'rxjs';
import { SharedService } from '../../../../shared/services/shared.service';
import { filter } from 'rxjs/operators';
import { URL_SERVICES } from '../../../../config/config';
import { CompanyService } from '../../../../shared/services/company.service';

@Component({
  selector: 'app-page-feria',
  templateUrl: './page-feria.component.html',
  styleUrls: ['./page-feria.component.scss']
})
export class PageFeriaComponent implements OnInit, OnDestroy {
  productSearch:any = [];
  layout: Layout = 'grid';
  grid: 'grid-3-sidebar'|'grid-4-full'|'grid-5-full' = 'grid-5-full';
  offcanvas: 'always'|'mobile' = 'mobile';
  order: string = 'a-z';
  previousUrl;
  // pageActual: number = 1;
  pageSize: number = 10;
  pagination = {
      last_page: 1,
      current_page:1,
      path: '',
      per_page:1,
      to: 1,
      total:1
  }

  productResourceOffer: Subscription;

  destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _productsService: ProductService,
    public sharedService: SharedService,
    public _companyService: CompanyService,
    private router: Router
  ) {
    this.getProductsByTerm();
  }

  ngOnInit(): void {
  }

  // sorting type ASC / DESC / A-Z / Z-A etc.
  public onChangeSorting(val) {
    this.sharedService.pageActualPromotions = 1;
    const url = URL_SERVICES + 'products-by-category/feria/1/'+this._companyService.user.id+'/'+val+`?page=${this.sharedService.pageActualPromotions}`
    // const url = this.pagination.path + '/' + val + `?page=${this.sharedService.pageActualPromotions}`
    this._productsService.getProductsOffer(null, url, val)
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
 }

  async getProductsByTerm() {
    await this._productsService.getProductsOffer('feria');

    this.productResourceOffer = this._productsService.productResourceOffer.subscribe(products => {
      if(products) {
        const productsOffer: any = products;
        productsOffer.map( (product) => {
          product.priceWithIva = product.price + (product.price * product.iva);

          return product;
        });

        this.productSearch = productsOffer;
        this.pagination = this._productsService.pagination.meta ? this._productsService.pagination.meta : { last_page: 1, current_page:1, path:'' }
      }
    })
  }

  newPage(event) {
    this.sharedService.pageActualPromotions = event;
    const url = this.pagination.path + `?page=${event}`;

    this._productsService.getProductsOffer(null, url);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  ngOnDestroy(): void {
    this.productResourceOffer.unsubscribe();
  }
}
