import {Component, OnDestroy, OnInit, HostListener, ChangeDetectorRef, Input} from '@angular/core';
import {CartService} from '../../../../shared/services/cart.service';
import {FormControl, Validators, FormGroup, FormBuilder} from '@angular/forms';
import {CartItem} from '../../../../shared/interfaces/cart-item';
import {Subject} from 'rxjs';
import {finalize, map, takeUntil} from 'rxjs/operators';
import {RootService} from '../../../../shared/services/root.service';
import {URL_SERVICES, URL_SERVICES_IMAGES, URL_SERVICES_IMG} from '../../../../config/config';
import Stepper from 'bs-stepper';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ModalCheckoutComponent} from '../../components/modal-checkout/modal-checkout.component';
import {CompanyService} from 'src/app/shared/services/company.service';
import {ModalNewBillComponent} from 'src/app/modules/account/components/modal-new-bill/modal-new-bill.component';
import {OrderService} from 'src/app/shared/services/order.service';
import swal from 'sweetalert2';
import {ToastrService} from 'ngx-toastr';
import {ConfigService} from '../../../../shared/services/config.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {DirectionService} from '../../../../shared/services/direction.service';
import {OwlOptions} from 'ngx-owl-carousel-o';

interface Item {
    cartItem: CartItem;
    quantity: number;
    quantityControl: FormControl;
}

@Component({
    selector: 'app-cart',
    templateUrl: './page-cart.component.html',
    styleUrls: ['./page-cart.component.scss']
})
export class PageCartComponent implements OnInit, OnDestroy {
    public bsModalRef: BsModalRef;
    private destroy$: Subject<void> = new Subject();
    URL_SERVICES_IMG = URL_SERVICES_IMG;
    public checkoutFormBilling: FormGroup;
    public addressBillingForm: FormGroup;
    removedItems: CartItem[] = [];
    items: Item[] = [];
    updating = false;
    newBilling = false;
    shippingAddress;
    disabledButtonFinish = false;
    public state = 'CIUDAD DE MEXICO';
    public type = 'Persona Fisica';
    public title = 'Mi Carrito';
    public innerWidth: any;
    billings: any;
    billing;
    address: any;
    bill: any;
    paymentMethods;
    paymentMethod = 1;
    pay_methods = [
        {id: '1', checked: true, description: 'Deposito Bancario', name: 'Depósito'},
        {id: '2', checked: false, description: 'Tarjeta de Credito/Debito', name: 'PayPal'},
        {id: '3', checked: false, description: 'Terminal Bancaria en contra entrega', name: 'Mercado Pago'},
        {id: '4', checked: false, description: 'Transferencia Bancaria Directa', name: 'Transferencia'}
    ];
    comment = '';

    @Input() withDepartments = false;
    slides = [];
    options = {
        nav: false,
        dots: true,
        loop: true,
        autoplay: true,
        autoWidth: true,
        responsive: {
            0: {items: 1}
        },
        rtl: this.direction.isRTL()
    };

    products = [];
    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        autoplay: true,
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 4
            }
        },
        nav: false,
        rtl: this.direction.isRTL()
    }

    private stepper: Stepper;

    @HostListener('window:resize', ['$event'])
    onRezise(event) {
        this.innerWidth = window.innerWidth;
    }

    isLoading: boolean = false;

    constructor(
        public root: RootService,
        public cart: CartService,
        private fb: FormBuilder,
        private modalService: BsModalService,
        public _companyService: CompanyService,
        private _orderService: OrderService,
        private toastr: ToastrService,
        private configService: ConfigService,
        private route: Router,
        private http: HttpClient,
        private cd: ChangeDetectorRef,
        private direction: DirectionService
    ) {
        this.getCarData().subscribe((response: any) => {
            console.log(response);
            response.sliders.map((slider) => {
                const slide = {
                    image_full: URL_SERVICES_IMAGES + 'bannersCar/' + slider.name,
                    type: slider.type,
                    url: slider.url
                }
                this.slides.push(slide);
            })

            response.products.map((product) => {
                product.priceWithIva = product.price + (product.price * product.iva);
                return product;
            });
            this.products = response.products;
        });

        this.checkoutFormBilling = this.fb.group({
            razon: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
            rfc: ['', [Validators.required]],
            street: ['', [Validators.required]],
            interior: [''],
            exterior: ['', Validators.required],
            colony: ['', Validators.required],
            city: ['', Validators.required],
            state: ['', Validators.required],
            ticket: [''],
            postal: ['', Validators.required],
            type: ['', Validators.required],
        })
    }

    ngOnInit(): void {
        this.innerWidth = window.innerWidth;
        this.billings = this.getBillings();
        this.cart.items$.pipe(
            takeUntil(this.destroy$),
            map(cartItems => cartItems.map(cartItem => {
                return {
                    cartItem,
                    quantity: cartItem.quantity,
                    quantityControl: new FormControl(cartItem.quantity, [Validators.required])
                };
            }))
        ).subscribe(items => {
            this.items = items;
            this.items.map((item) => {
                if (item.cartItem.product.temporada_oferta === 'S' && item.cartItem.product.pieces_offer <= item.quantity) {
                    return item.cartItem.product.priceProduct = item.cartItem.product.price_offer / (1 + +item.cartItem.product.iva);
                } else {
                    return item.cartItem.product.priceProduct = item.cartItem.product.price;
                }
            });

        });
        setTimeout(() => {
            this.stepper = new Stepper(document.querySelector('#stepper1'), {
                linear: false,
                animation: true
            })
        }, 1000);

        this.getPayments()
    }

    saveComment(event) {
        this.comment = event.target.value;
    }

    getPayments() {
        this._orderService.getPaymentMethod().subscribe((paymentMethods: any) => {
            paymentMethods.map((paymentMethod) => {
                paymentMethod.checked = paymentMethod.checked == '1' ? true : false
            });
            this.paymentMethods = paymentMethods;
        })
    }

    addNewAddress(address) {
        this.bsModalRef = this.modalService.show(ModalNewBillComponent, Object.assign({}, {class: 'modal-lg'}));
        this.bsModalRef.content.closeBtnName = 'Cancelar';
        this.bsModalRef.content.continueBtnName = 'Solicitar';
    }

    sendAddress() {

    }

    changeTypePayment(item: any) {
        if (item.id === 7) {
            if (this._companyService.user.credit < this.cart.data.total) {
                this.toastr.error(`Su crédito no es suficiente para costear este .. Favor enviarlo y comunicarse con su agente de ventas`);
                return;
            }
        }
        this.paymentMethod = item.id;
    }

    openModalNewBilling() {
        this.bsModalRef = this.modalService.show(ModalNewBillComponent, Object.assign({}, {class: 'modal-lg'}));
        this.bsModalRef.content.closeBtnName = 'Cancelar';
        this.bsModalRef.content.continueBtnName = 'Agregar';
        this.modalService.onHide.subscribe(resp => {
            if (resp === 'added') {
                this.billings = this.getBillings();
            }
        })
    }

    getBillings() {
        const billings = this._companyService.user.addressBilling.map((addressDetail) => {
            if (addressDetail.default === 1) {
                addressDetail.checked = true;
            } else {
                addressDetail.checked = false;
            }

            return addressDetail;
        });
        return billings;
    }

    openModal() {
        this.bsModalRef = this.modalService.show(ModalCheckoutComponent, Object.assign({}, {class: 'modal-lg'}, {
            keyboard: false, ignoreBackdropClick: true
        }));
        this.bsModalRef.content.closeBtnName = 'Cerrar';
        this.bsModalRef.content.continueBtnName = 'Seguir Comprando';
    }

    changeTitle(title: string) {
        this.title = title;
    }

    changeQuantity() {
        this.items = this.items.map(product => {
            if (product.quantity !== product.quantityControl.value) {
                product.quantity = product.quantityControl.value;
            }
            return product;
        });
    }

    next(e?) {
        this.update();

        this.configService.getConfig().subscribe((config: any) => {
            if (e) {
                const {from} = e;
                const availables = [];

                if (from === 'cart') {
                    this.cart.data.items.forEach((item) => {
                        if (item.quantity > item.product.stock || item.product.status !== 1) {
                            availables.push(item.product.name);
                        }
                    });
                }

                let element = '';

                if (availables.length > 0) {
                    // for (let index = 0; index < availables.length; index++) {
                    //     element += availables[index];
                    //     if(index !== availables.length - 1){
                    //         element += ', ';
                    //     }
                    // }
                    // this.toastr.error(`No contamos con existencia suficiente para los productos: ${element}`);
                    return;
                }

                if (this.cart.data.total < config.min) {
                    this.toastr.error(`El mínimo de compra es de: $${config.min} MXN`);
                    this.route.navigate(['/']);
                }

                switch (from) {
                    case 'cart':
                        this.title = 'Dirección de Envío'
                        break;
                    case 'address':
                        this.title = 'Facturación'
                        break;
                    case 'bill':
                        this.title = 'Formas de Pago'
                        break;

                    default:
                        this.title = 'Mi Carrito'
                        break;
                }
            }

            if (e.value) {
                this.shippingAddress = e.value;
            }

            if (e.billingAddress) {
                this.billing = e.billingAddress;
            }

            this.stepper.next();
            try {
                window.scrollTo({top: 200, left: 0, behavior: 'smooth'});
            } catch {
                window.scrollTo(0, 0);
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    newBillingAddress() {
        this.checkoutFormBilling.reset();
        this.newBilling = true;
    }

    remove(item: CartItem): void {
        if (this.removedItems.includes(item)) {
            return;
        }

        this.removedItems.push(item);
        this.cart.remove(item).subscribe({complete: () => this.removedItems = this.removedItems.filter(eachItem => eachItem !== item)});
    }

    update($event?) {
        const productNoQty = [];
        const productMinusZero = [];
        const productMinusOffer = [];
        const quantityDecimal = [];

        this.items.map((item) => {
            if (item.quantityControl.value > item.cartItem.product.stock) {
                productNoQty.push(item.cartItem.product.name);
            }

            if (item.quantityControl.value <= 0) {
                productMinusZero.push(item.quantityControl.value);
            }

            if (item.quantityControl.value < item.cartItem.product.pieces_offer) {
                productMinusOffer.push(item.cartItem.product.pieces_offer);
            }

            // if(item.cartItem.product.price_offer > 0 && item.cartItem.product.pieces_offer <= item.quantity ) {
            //     return item.cartItem.product.price = item.cartItem.product.price_offer;
            // }


        });

        if (productNoQty.length > 0) {
            this.toastr.error(`No contamos con existencia suficiente para el producto ${productNoQty[0]}`);
            return;
        }

        if (productMinusZero.length > 0) {
            this.toastr.error('No puede agregar un número menor a 1, si no desea un producto puede eliminarlo');
            return;
        }

        this.updating = true;
        this.cart.update(
            this.items
                .filter(item => {
                    return item.quantityControl.value !== item.quantity
                })
                .map(item => ({
                    item: item.cartItem,
                    quantity: item.quantityControl.value
                }))
        ).subscribe({complete: () => this.updating = false});
    }

    needUpdate(): boolean {
        let needUpdate = false;

        for (const item of this.items) {
            if (!item.quantityControl.valid) {
                return false;
            }

            if (item.quantityControl.value !== item.quantity) {
                needUpdate = true;
            }
        }

        return needUpdate;
    }

    createOrder() {
        this.disabledButtonFinish = true;
        swal.fire({
            title: 'Atencion',
            text: 'Esta a punto de confirmar su pedido',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, confirmar'
        }).then((create) => {
            if (create.value) {
                this.isLoading = true;
                this.cd.detectChanges();
                this._orderService.createOrder(
                    this.cart.data.items, this.cart.data.total,
                    this.shippingAddress, this.billing, this.paymentMethod, this.comment
                ).then(resp => {
                    this._companyService.loadUser().pipe(
                        takeUntil(this.destroy$),
                        finalize(() => {
                            this.isLoading = false;
                            this.disabledButtonFinish = false;
                            this.cd.detectChanges();
                        })
                    ).subscribe({
                        next: (user: any) => {
                            localStorage.setItem('user', JSON.stringify(user));
                            this._companyService.user = user;
                            this._orderService.nroOrder = resp.id;
                            this._orderService.payMethod = resp.pay_method;
                            this._orderService.sucursal = resp.sucursal.name;
                            this.cart.removeAllCart();
                            this.route.navigate(['/carrito/checkout/exito']);
                        },
                        error: (error) => {
                            console.error('Error al cargar el usuario:', error);
                            this.toastr.error('Ha ocurrido un error al procesar su pedido. Por favor, inténtelo de nuevo.');
                        }
                    });
                }).catch(error => {
                    console.error('Error al crear el pedido:', error);
                    this.isLoading = false;
                    this.disabledButtonFinish = false;
                    this.cd.detectChanges();
                    this.toastr.error('Ha ocurrido un error al procesar su pedido. Por favor, inténtelo de nuevo.');
                });
            } else {
                this.disabledButtonFinish = false;
            }
        });
    }

    getCarData() {
        const user = this._companyService.user.id;
        const sucursal = this._companyService.user.sucursal;
        const url = URL_SERVICES + 'car/getData/' + user + '/' + sucursal;
        return this.http.get(url);
    }
    getImage(product){
        if (product.coverImage.startsWith('http')) {
            return product.coverImage;
          }else if(product.coverImage  === 'no-photo.png'){
           return URL_SERVICES_IMG + '/' + product.coverImage;
          }
          return URL_SERVICES_IMG + '/' + product.id + '/' + product.coverImage

    }
}
