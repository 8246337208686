<!-- .block-slideshow -->
<div class="mb-0 block-slideshow block" [ngClass]="{'block-slideshow--layout--full': !withDepartments, 'block-slideshow--layout--with-departments': withDepartments}">
    <div class="container">
        <div class="row">
            <div *ngIf="withDepartments" class="col-3 d-lg-block d-none" appDepartmentsArea></div>
            <div class="col-12" [ngClass]="{'col-lg-12': !withDepartments, 'col-lg-9': withDepartments}">
                <div class="block-slideshow__body">
                    <owl-carousel-o *ngIf="slides?.length" [options]="options" appOwlPreventClick>
                        <ng-template *ngFor="let slide of slides" carouselSlide>
                            <a class="block-slideshow__slide" (click)="incrementSlider(slide.id)" href="{{slide.url}}" target="{{slide.type === 'Exterior' ? '_blank' : ''}}" appClick>
                                <div class="block-slideshow__slide-image block-slideshow__slide-image--desktop" [ngStyle]="{'background-image': 'url('+(withDepartments ? slide.image_classic : slide.image_full)+')'}"></div>
                                <div class="block-slideshow__slide-image block-slideshow__slide-image--mobile" [ngStyle]="{'background-image': 'url('+slide.image_mobile+')'}"></div>
                                <!-- <div class="block-slideshow__slide-content">
                                    <div class="block-slideshow__slide-title" [innerHTML]="sanitizer.bypassSecurityTrustHtml(slide.title)"></div>
                                    <div class="block-slideshow__slide-text" [innerHTML]="sanitizer.bypassSecurityTrustHtml(slide.text)"></div>
                                    <div class="block-slideshow__slide-button">
                                        <span class="btn btn-primary btn-lg">Shop Now</span>
                                    </div>
                                </div> -->
                            </a>
                        </ng-template>
                    </owl-carousel-o>
                </div>
                <!-- <div class="block mt-4 mb-3">
                    <app-block-features></app-block-features>
                </div> -->
            </div>
            <div class="col-12 mt-3">
                    <owl-carousel-o *ngIf="carousels?.length" [options]="customOptions" appOwlPreventClick>
                        <ng-template *ngFor="let carousel of carousels" carouselSlide>
                            <div class="box-lab">
                                <img src="{{carousel.image}}">
                                <div class="icon trade">
                                    <a (click)="test(carousel.id)" style="cursor: pointer">
                                        <img src="{{carousel.logo}}">
                                    </a>
                                </div>
                                <h6 class="cst-row box-products">{{carousel.name_short}}</h6>
                                <div class="cst-row text-center box-products">
                                    <a href="/producto/{{carousel.products[0].slug}}">
                                        <img class="miniatura" src="{{carousel.products[0].image}}">
                                    </a>
                                    <a href="/producto/{{carousel.products[1].slug}}">
                                        <img class="miniatura" src="{{carousel.products[1].image}}">
                                    </a>
                                    <a href="/producto/{{carousel.products[2].slug}}">
                                        <img  class="miniatura" src="{{carousel.products[2].image}}">
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
            </div>
            <ng-container *ngIf="banners.length > 0">
                <!-- <div class="caja"> -->
                <div class="col-12 mt-3">
                    <img style="cursor: pointer" src="{{ banners[0].image }}" class="w-100 img-responsive" (click)="gotToUrl(banners[0].id, banners[0].url, banners[0].type)" alt="">
                </div>
                <!-- </div> -->
                <div class="col-6 mt-3 pr-1">
                    <img style="cursor: pointer" src="{{ banners[1].image }}" class="w-100 img-responsive" (click)="gotToUrl(banners[1].id, banners[1].url, banners[1].type)" alt="">
                </div>
                <div class="col-6 mt-3">
                    <img style="cursor: pointer" src="{{ banners[2].image }}" class="w-100 img-responsive" (click)="gotToUrl(banners[2].id, banners[2].url, banners[2].type)" alt="">
                </div>
                <div class="col-12 mt-3">
                    <img style="cursor: pointer" src="{{ banners[3].image }}" class="w-100 img-responsive" (click)="gotToUrl(banners[3].id, banners[3].url, banners[3].type)" alt="">
                </div>
                <div class="col-6 mt-3 pr-1">
                    <img style="cursor: pointer" src="{{ banners[4].image }}" class="w-100 img-responsive" (click)="gotToUrl(banners[4].id, banners[4].url, banners[4].type)" alt="">
                </div>
                <div class="col-6 mt-3">
                    <img style="cursor: pointer" src="{{ banners[5].image }}" class="w-100 img-responsive" (click)="gotToUrl(banners[5].id, banners[5].url, banners[5].type)" alt="">
                </div>
                <div class="col-12 mt-3">
                    <img style="cursor: pointer" src="{{ banners[6].image }}" class="w-100 img-responsive" (click)="gotToUrl(banners[6].id, banners[6].url, banners[6].type)" alt="">
                </div>
            </ng-container>

        </div>
    </div>
</div>
<!-- .block-slideshow / end -->
