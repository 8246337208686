import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {SharedService} from '../../../../shared/services/shared.service';
import {NotificationService} from '../../../../shared/services/notification.service';

@Component({
    selector: 'app-notification',
    templateUrl: './page-notification.component.html',
    styleUrls: ['./page-notification.component.scss']
})
export class PageNotificationComponent implements OnInit, OnDestroy {
    offcanvas: 'always'|'mobile' = 'mobile';
    notifications: any = [];
    pagination = {
        last_page: 1,
        current_page: 1,
        path: '',
        per_page: 1,
        to: 1,
        total: 1
    }

    notificationsSubscription: Subscription;

    constructor(public sharedService: SharedService,
                public notificationService: NotificationService) {
        this.getAllNotifications();
    }

    ngOnInit(): void {
    }

    async getAllNotifications() {
        await this.notificationService.getAllNotifications();

        this.notificationsSubscription = this.notificationService.notificationsListSubject$.subscribe(notifications => {
            if (notifications) {
                this.notifications = notifications;

                this.pagination = this.notificationService.pagination.meta ? this.notificationService.pagination.meta : {
                    last_page: 1,
                    current_page: 1,
                    path: ''
                }
            }
        })
    }

    newPage(event) {
        this.sharedService.pageActualNotification = event;
        const url = this.pagination.path + `?page=${event}`;

        this.notificationService.getAllNotifications();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    ngOnDestroy(): void {
        this.notificationsSubscription.unsubscribe();
    }
}
