import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ShopService} from 'src/app/shared/api/shop.service';
import {Layout} from '../../components/products-view/products-view.component';
import {ProductService} from '../../services/products.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {URL_SERVICES} from '../../../../config/config';
import {CompanyService} from '../../../../shared/services/company.service';

interface BannerImage {
    url: string;
    alt: string;
}
@Component({
    selector: 'app-page-search',
    templateUrl: './page-search.component.html',
    styleUrls: ['./page-search.component.scss']
})
export class PageSearchComponent implements OnInit {
    productSearch: any = [];
    layout: Layout = 'grid';
    grid: 'grid-3-sidebar' | 'grid-4-full' | 'grid-5-full' = 'grid-5-full';
    offcanvas: 'always' | 'mobile' = 'mobile';
    order: string = 'a-z';
    // pageActual: number = 1;
    pageSize: number = 10;
    term: string;
    pagination = {
        last_page: 1,
        current_page: 1,
        path: '',
        per_page: 1,
        to: 1,
        total: 1
    }
    bannerImages: BannerImage[] = [];
    currentBannerIndex: number = 0;
    autoSlideInterval: any;

    constructor(
        private activatedRoute: ActivatedRoute,
        private _shopService: ShopService,
        public sharedService: SharedService,
        public _companyService: CompanyService
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            const {term} = params;
            if (term.trim() && term.trim().length > 0) {
                this.term = term.trim();
                this.getProductsByTerm(term.trim());
                this.loadBannerImages(term.trim());
            }
        })
    }

    ngOnInit(): void {
        this.startAutoSlide();
    }
    ngOnDestroy(): void {
        this.stopAutoSlide();
    }
    private loadBannerImages(searchTerm: string): void {
        // Aquí implementarías la lógica para cargar las imágenes según el término de búsqueda
        // Este es un ejemplo - deberías adaptarlo a tu lógica de negocio
        const term = searchTerm.replace('*', '').toUpperCase();

        const bannerMapping = {
            PRO: [{ url: 'assets/labs/PROGELA.png', alt: 'Progela' }],
            AVI: [{ url: 'assets/labs/AVIVIA.png', alt: 'Avivia' }],
            GNL: [{ url: 'assets/labs/GENOMMA.png', alt: 'Genomma' }],
            LAN: [{ url: 'assets/labs/LANDSTEINER.png', alt: 'Landsteiner' }],
            MVE: [{ url: 'assets/labs/MAVER_NATUREX.png', alt: 'Mavernaturex' }],
            NTX: [{ url: 'assets/labs/MAVER_NATUREX.png', alt: 'Mavernaturex' }],
            SFR: [{ url: 'assets/labs/SOLFRAN.png', alt: 'Solfran' }],
            ULT: [{ url: 'assets/labs/ULTRA.png', alt: 'Ultra' }],
            LOE: [{ url: 'assets/labs/LOEFFLER.png', alt: 'Loeffler' }],
            SRR: [{ url: 'assets/labs/SERRAL.png', alt: 'Serral' }],
            SUA: [{ url: 'assets/labs/SUANCA.png', alt: 'Suanca' }],
            MVI: [{ url: 'assets/labs/MAVI.png', alt: 'Mavi' }],
            NUC: [{ url: 'assets/labs/NUCITEC.png', alt: 'Nucitec' }],
            // Agrega más mappings aquí
        };

        // Busca la primera coincidencia en las claves
        const matchedKey = Object.keys(bannerMapping).find(key => term.includes(key));
        this.bannerImages = matchedKey ? bannerMapping[matchedKey] : [];
    }

    nextBanner(): void {
        this.currentBannerIndex = (this.currentBannerIndex + 1) % this.bannerImages.length;
    }

    previousBanner(): void {
        this.currentBannerIndex = this.currentBannerIndex === 0
            ? this.bannerImages.length - 1
            : this.currentBannerIndex - 1;
    }

    selectBanner(index: number): void {
        this.currentBannerIndex = index;
    }

    private startAutoSlide(): void {
        if (this.bannerImages.length > 1) {
            this.autoSlideInterval = setInterval(() => {
                this.nextBanner();
            }, 5000); // Cambia la imagen cada 5 segundos
        }
    }

    private stopAutoSlide(): void {
        if (this.autoSlideInterval) {
            clearInterval(this.autoSlideInterval);
        }
    }

    // sorting type ASC / DESC / A-Z / Z-A etc.
    public onChangeSorting(val) {
        this.sharedService.pageActualSearch = 1;
        const url = URL_SERVICES + 'get-products-search/' + this.term + '/' + this._companyService.user.id + '/20000/' + val + `?page=${this.sharedService.pageActualPromotions}`

        this._shopService.getSearchTermUrl(url).then((resp) => {
            this.productSearch = resp;

            this.pagination = this.productSearch.meta ? this.productSearch.meta : {last_page: 1, current_page: 1, path: ''}
        });
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getProductsByTerm(term: string) {
        const products: any = await this._shopService.getSearchTerm(term);
        // this.productSearch = await this._shopService.getProductsLista();
        products.data.map((product) => {
            product.priceWithIva = product.price + (product.price * product.iva);
            return product;
        });
        this.productSearch = products;

        this.pagination = this.productSearch.meta ? this.productSearch.meta : {last_page: 1, current_page: 1, path: ''}
    }

    newPage(event) {
        this.sharedService.pageActualSearch = event;
        const url = this.pagination.path + `?page=${event}`
        this._shopService.getSearchTermUrl(url, null).then((resp: any) => {
            resp.data.map((product) => {
                product.priceWithIva = product.price + (product.price * product.iva);
                return product;
            });
            this.productSearch = resp;
            this.pagination = this.productSearch.meta ? this.productSearch.meta : {last_page: 1, current_page: 1, path: ''}
        });
    }
}
