<div class="site-footer mt-0">
    <div class="container">
        <div class="site-footer__widgets">
            <div class="row logo-footer">
                <div class="col-12 text-right">
                    <div class="site-footer__widget footer-contacts">
                        <img src="assets/brand/logofooter.png" alt="farmater-logo" style="margin-right: 20px;" width="180px">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-4 col-lg-3">
                    <app-footer-links header="Compañia" [links]="[
                        {label: 'Nosotros', url: 'https://www.farmater.com.mx/nosotros', external: true, target: '_blank'},
                        {label: 'Servicios',  url: 'https://www.farmater.com.mx/servicios', external: true, target: '_blank'},
                        {label: 'Socios Comerciales', url: 'https://www.farmater.com.mx/socios-comerciales', external: true, target: '_blank'},
                        {label: 'Blog', url: 'https://www.farmater.com.mx/blog', external: true, target: '_blank'},
                        {label: 'Contacto', url: '/contacto'}
                    ]"></app-footer-links>
                </div>
                <div class="col-12 col-md-4 col-lg-3">
                    <app-footer-links header="Compra Online" [links]="[
                        {label: 'Compra Masiva', url:'/compra-masiva'},
                        {label: 'Boletín de Ofertas', url: 'https://farmaterventas.com/boletin-ofertas/', external: true, target: '_blank'},
                        {label: 'Preguntas Frecuentes', url: 'https://www.farmater.com.mx/preguntas-frecuentes', external: true, target: '_blank'},
                        {label: 'Políticas de Envío', url: ''},
                        {label: 'Devoluciones y Cancelaciones', url: ''}
                    ]"></app-footer-links>
                </div>
                <div class="col-12 col-md-4 col-lg-3">
                    <app-footer-links header="Seguridad" [links]="[
                        {label: 'Ayuda', url: 'https://www.farmater.com.mx/contacto', external: true, target: '_blank'},
                        {label: 'Aviso y Privacidad', url: 'https://www.farmater.com.mx/aviso-de-privacidad', external: true, target: '_blank'},
                        {label: 'Terminos y Condiciones', url: ''}
                    ]"></app-footer-links>
                </div>
                <!-- <div class="col-12 col-md-12 col-lg-4">
                    <app-footer-newsletter class="site-footer__widget"></app-footer-newsletter>
                </div> -->
                <div class="col-12 col-md-4 col-lg-3">
                    <app-footer-contacts></app-footer-contacts>
                </div>
            </div>
        </div>
        <div class="site-footer__bottom">
            <div class="site-footer__copyright">
                Copyright <i class="fas fa-copyright    "></i> 2020 FARMATER, S.A DE C.V.. Todos los derechos reservados, Diseño web por dosbytes.com.mx </div>
            <!-- <div class="site-footer__payments">
                <img src="assets/images/payments.png" alt="">
            </div> -->
        </div>
    </div>
    <app-totop></app-totop>
</div>
