import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { AddressService } from 'src/app/shared/services/address.service';
import { CompanyService } from 'src/app/shared/services/company.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-new-address',
  templateUrl: './modal-new-address.component.html',
  styleUrls: ['./modal-new-address.component.scss']
})
export class ModalNewAddressComponent implements OnInit {
  checkoutFormAddress: FormGroup;
  closeBtnName: string;
  continueBtnName: string;
  user:any;
  address:any;
  disabled = false;
  spinner = false;

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    private fb: FormBuilder,
    private _addressService: AddressService,
    private _companyService: CompanyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {

    this.checkoutFormAddress = this.fb.group({
      street: ['', [Validators.required]],
      interior: [''],
      exterior: ['', Validators.required],
      colony: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      ticket: [''],
      postal: ['', Validators.required],
    })
  }

  sendRequest() {
    this.disabled = true;
    this.spinner = true;
    const data = this.checkoutFormAddress.value;
  
    this._companyService.sendAddress(data).subscribe( (resp: any) => {
      this.bsModalRef.hide()
      this.disabled = false;
      this.spinner = false;
      this.toastr.success(`La solicitud se envio correctamente`);
    }, (error) => {
      this.bsModalRef.hide()
      this.disabled = false;
      this.spinner = false;
      this.toastr.error(`Error enviando solicitud`);
    });
  }

  getFormValidationErrors() {
    Object.keys(this.checkoutFormAddress.controls).forEach(key => {

    const controlErrors: ValidationErrors = this.checkoutFormAddress.get(key).errors;
    if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            // console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });
    }

}
