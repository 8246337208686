import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CompanyService } from '../shared/services/company.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public _companyService: CompanyService,
    public router: Router,
    public _toastr: ToastrService
  ) {}
  canActivate(){
    if ( this._companyService.isLoginClient()  || this._companyService.isLoginCompany() ) {
      return true;
    } else {
      this._toastr.success(`No está autenticado`);
      this.router.navigate(['/login']);
      return true;
    }
  }
  
}
