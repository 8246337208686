<div class="account-menu">
    <form class="account-menu__form" action="" [formGroup]="forma" (ngSubmit)="login()" *ngIf="!_companyService.isLogin()">
        <div class="account-menu__form-title">Ingrese a su cuenta</div>
        <div class="form-group">
            <label for="header-signin-email" class="sr-only">E-mail</label>
            <input id="header-signin-email" type="email" formControlName="email" class="form-control form-control-sm" placeholder="Email address">
        </div>
        <div class="form-group">
            <label for="header-signin-password" class="sr-only">Contraseña</label>
            <div class="account-menu__form-forgot">
                <input id="header-signin-password" type="password" formControlName="password" class="form-control form-control-sm" placeholder="Contraseña">
                <a routerLink="/olvido-contrasena" class="account-menu__form-forgot-link">Olvido su Contraseña?</a>
            </div>
        </div>
        <div class="form-group account-menu__form-button">
            <button type="submit" class="btn btn-primary btn-sm">Entrar</button>
        </div>
        <div class="account-menu__form-link"><a routerLink="/login" (click)="closeMenu.emit()">Crear una cuenta</a></div>
    </form>
    <div class="account-menu__divider"></div>
    <!-- <a routerLink="/cuenta/dashboard" class="account-menu__user" (click)="closeMenu.emit()" *ngIf="_companyService.isLoginClient()"> -->
    <a [routerLink]="link" class="account-menu__user" (click)="closeMenu.emit()" *ngIf="_companyService.isLogin()">
        <div class="account-menu__user-avatar">
            <img src="./assets/images/avatars/avatar-3.jpg" *ngIf="!_companyService.user.image">
            <img width="50px" height="50px" style="max-width:50px; max-height: 50px;" [src]="_companyService.user.image | image:'user': _companyService.user.id" *ngIf="_companyService.user.image">
        </div>
        <div class="account-menu__user-info" *ngIf="_companyService.isLogin()">
            <!-- <div routerLink="/empresa/dashboard" class="account-menu__user-name" *ngIf="_companyService.isLoginCompany()">{{ _companyService.user.profile.name_company }}</div> -->
            <div routerLink="/cuenta/dashboard" class="account-menu__user-name" >{{ _companyService.user.profile.name }} {{ _companyService.user.profile.lastname }}</div>
            <div class="account-menu__user-email" *ngIf="_companyService.isLogin()">{{ _companyService.user.email }}</div>
        </div>
    </a>
    <div class="account-menu__divider"></div>
    <!-- <ul class="account-menu__links">
        <li><a routerLink="/cuenta/perfil" (click)="closeMenu.emit()">Editar Perfil</a></li>
        <li><a routerLink="/cuenta/ordenes" (click)="closeMenu.emit()">Historial de Compras</a></li>
        <li><a routerLink="/cuenta/direcciones" (click)="closeMenu.emit()">Direcciones</a></li>
        <li><a routerLink="/cuenta/contrasena" (click)="closeMenu.emit()">Contraseña</a></li>
    </ul> -->
    <div class="account-menu__divider"></div>
    <ul class="account-menu__links" *ngIf="_companyService.isLogin()">
        <li><a class="pointer" (click)="_companyService.logout()">Salir</a></li>
    </ul>
</div>