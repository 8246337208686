<div class="container mt-4">
    <!-- Grid container para el contenido principal y banner -->
    <div class="row">
        <!-- Contenido principal -->
        <div class="col-lg-9">
            <div class="products-view">
                <div class="block">
                    <div *ngIf="productSearch.data.length" class="products-view__content">
                        <div class="products-view__options mt-3">
                            <div class="view-options" [ngClass]="{
                                'view-options--offcanvas--always': offcanvas === 'always',
                                'view-options--offcanvas--mobile': offcanvas === 'mobile'
                            }">
                                <div class="view-options__legend">
                                    Viendo {{ this.pagination.per_page > this.pagination.total ? this.pagination.to : this.pagination.per_page }}
                                    –{{ this.pagination.to }} de {{ this.pagination.total }} resultados
                                </div>
                                <div class="view-options__divider"></div>
                                <div class="view-options__control">
                                    <label for="view-options-sort">Ordenar por</label>
                                    <div>
                                        <select [(ngModel)]="order" class="form-control form-control-sm" id="view-options-sort"
                                                (change)="onChangeSorting($event.target.value)">
                                            <option value="a-z">Nombre (A-Z)</option>
                                            <option value="z-a">Nombre (Z-A)</option>
                                            <option value="price-a">Precio ASC</option>
                                            <option value="price-z">Precio DESC</option>
                                            <option value="sustancias">Sustancias</option>
                                            <option value="department">Laboratorio</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="productSearch.data.length > 0" class="products-view__list products-list"
                             [attr.data-layout]="layout != 'list' ? grid : layout"
                             [attr.data-with-features]="layout == 'grid-with-features' ? 'true' : 'false'">
                            <div class="products-list__body">
                                <div *ngFor="let product of productSearch.data | paginate: { itemsPerPage: 20, currentPage: sharedService.pageActualSearch, totalItems: this.pagination.total }"
                                    class="products-list__item">
                                    <app-product-card [product]="product"></app-product-card>
                                </div>
                            </div>
                        </div>

                        <div class="products-view__pagination">
                            <pagination-controls class="rounded text-center rounded-circle"
                                               responsive="true"
                                               previousLabel=""
                                               nextLabel=""
                                               (pageChange)="newPage($event)">
                            </pagination-controls>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!productSearch.data.length" class="products-view__empty">
                <div class="products-view__empty-title">No hay elementos coincidentes.</div>
                <div class="products-view__empty-subtitle">Intenta con otra busqueda</div>
                <button type="button" class="btn btn-primary btn-sm" routerLink="/">Ir a la Tienda</button>
            </div>
        </div>

        <!-- Banner lateral -->
<!--        <div *ngIf="productSearch.data.length" class="col-lg-3 d-none d-lg-block">-->
<!--            <div class="banner-sidebar">-->
<!--                <img src="assets/labs/avivia1.png" alt="Banner promocional Ultra" class="img-fluid sticky-banner">-->
<!--            </div>-->
<!--        </div>-->

        <div class="col-lg-3 d-none d-lg-block">
            <div class="banner-sidebar" *ngIf="bannerImages.length > 0">
                <div class="banner-carousel">
                    <!-- Imágenes del carrusel -->
                    <div class="banner-carousel__container">
                        <img [src]="bannerImages[currentBannerIndex].url"
                             [alt]="bannerImages[currentBannerIndex].alt"
                             class="img-fluid sticky-banner">

                        <!-- Controles del carrusel -->
                        <div class="banner-carousel__controls" *ngIf="bannerImages.length > 1">
                            <button class="carousel-control prev" (click)="previousBanner()">
                                <i class="fas fa-chevron-left"></i>
                            </button>
                            <button class="carousel-control next" (click)="nextBanner()">
                                <i class="fas fa-chevron-right"></i>
                            </button>
                        </div>

                        <!-- Indicadores -->
                        <div class="banner-carousel__indicators" *ngIf="bannerImages.length > 1">
                            <button *ngFor="let image of bannerImages; let i = index"
                                    [class.active]="i === currentBannerIndex"
                                    (click)="selectBanner(i)"
                                    class="indicator-dot">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
