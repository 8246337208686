import {Component, OnDestroy, OnInit, ChangeDetectorRef, Output, EventEmitter} from '@angular/core';
import {CartService} from '../../../../shared/services/cart.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {RootService} from '../../../../shared/services/root.service';
import {URL_SERVICES, URL_SERVICES_IMG} from '../../../../config/config';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {CompanyService} from '../../../../shared/services/company.service';
import {OrderService} from '../../../../shared/services/order.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import swal from 'sweetalert2';
import {ToastrService} from 'ngx-toastr';
import {ModalAddressComponent} from '../../components/modal-address/modal-address.component';
import {combineLatest, Subscription} from 'rxjs';
import {ModalNewAddressComponent} from 'src/app/modules/account/components/modal-new-address/modal-new-address.component';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-checkout',
    templateUrl: './page-checkout.component.html',
    styleUrls: ['./page-checkout.component.scss']
})
export class PageCheckoutComponent implements OnInit, OnDestroy {
    @Output() nextstep = new EventEmitter;
    private destroy$: Subject<void> = new Subject();
    public checkoutForm: FormGroup;
    public checkoutFormBilling: FormGroup;
    public amount: number;
    public addressPrincipal: boolean = true;
    public state = 'CIUDAD DE MEXICO';
    public type = 'Persona Fisica';
    public user;
    public addresses = [];
    public newAddress = false;
    public newBilling = false;
    public applyShipping = false;
    public total: number;
    public url = URL_SERVICES_IMG;
    URL_SERVICES_IMG = URL_SERVICES_IMG;
    public bsModalRef: BsModalRef;
    subscriptions: Subscription[] = [];
    public _sucursals: any = [];

    constructor(
        private fb: FormBuilder,
        public root: RootService,
        public cart: CartService,
        private route: ActivatedRoute,
        private _orderService: OrderService,
        private router: Router,
        private _toastr: ToastrService,
        public _companyService: CompanyService,
        private modalService: BsModalService,
        private changeDetection: ChangeDetectorRef,
        private http: HttpClient
    ) {
        this.getSucursales().subscribe(sucursals => {
            this._sucursals = sucursals;
        })
        this.checkoutForm = this.fb.group({
            // email: ['', [Validators.required, Validators.email]],
            street: ['', [Validators.required, Validators.maxLength(50)]],
            colony: ['', Validators.required],
            city: ['', Validators.required],
            state: ['', Validators.required],
            postal: [''],
            // country: ['', Validators.required],
            interior: [''],
            exterior: ['', Validators.required],
            sucursal: ['', Validators.required]
        });
        this.checkoutFormBilling = this.fb.group({
            nameBilling: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
            rfcBilling: ['', [Validators.required]],
            cfdiBilling: ['', [Validators.required]],
            emailBilling: ['', [Validators.required, Validators.email]],
            streetBilling: ['', [Validators.required]],
            interiorBilling: ['', Validators.required],
            exteriorBilling: ['', Validators.required],
            colonyBilling: ['', Validators.required],
            delegacionBilling: ['', Validators.required],
            cityBilling: ['', Validators.required],
            stateBilling: ['', Validators.required],
            postalBilling: [''],
            countryBilling: ['', Validators.required],
            typeBilling: ['', Validators.required],
        })
    }

    ngOnInit(): void {
        this.addresses = this.getAddresses();
        this._companyService.getUser().subscribe(user => {
            this.user = user;
            if (!this.user.shippingAddress) {
                this.addressPrincipal = false;
                this.user.shippingAddress = {
                    nombre: this.user.name,
                    apellido: this.user.lastname,
                    phone: '',
                    city: '',
                    address: '',
                    postal: '',
                };
            }
        }, (error) => {
            this._companyService.logout();
            this.router.navigate(['/login']);
        });

        // this.cart.quantity$.pipe(takeUntil(this.destroy$)).subscribe(quantity => {
        //     if (!quantity) {
        //         this.router.navigate(['../cart'], {relativeTo: this.route}).then();
        //     }
        // });
    }

    getAddresses() {
        const address = this._companyService.user.address.map((addressDetail) => {
            if (addressDetail.default === 1) {
                addressDetail.checked = true;
            } else {
                addressDetail.checked = false;
            }

            return addressDetail;
        });
        return address;
    }

    nextStepCheckout() {
        if (this.checkoutForm.invalid) {
            this._toastr.error(`Debe agregar una dirección de envío`);
            return;
        }
        const address = this.checkoutForm.value;
        // this.addresses.filter(address => address.checked === true);

        this.nextstep.emit({from: 'address', value: address});
    }

    shippingAddress(event) {
        this.user.shippingAddress = event;
    }

    openModalNewAddress() {
        const initialState = {
            user: this.user,
        }
        this.bsModalRef = this.modalService.show(ModalAddressComponent, {initialState})
        this.bsModalRef.content.closeBtnName = 'Cancelar';
        this.bsModalRef.content.continueBtnName = 'Agregar';
        this.modalService.onHide.subscribe(resp => {
            if (resp === 'added') {
                this.addresses = this.getAddresses();
            }
        });
    }

    addNewAddress() {
        this.bsModalRef = this.modalService.show(ModalNewAddressComponent, Object.assign({}, {class: 'modal-lg'}));
        this.bsModalRef.content.closeBtnName = 'Cancelar';
        this.bsModalRef.content.continueBtnName = 'Solicitar';
    }

    applyShippingAddress(event) {
        if (event.target.checked) {
            this.applyShipping = true;
            this.newBilling = false;
        } else {
            this.applyShipping = false;
        }
    }

    newBillingAddress() {
        this.checkoutFormBilling.reset();
        this.newBilling = true;
    }

    getAddress() {
        if (this._companyService.user.billingAddress.id) {
            return true;
        } else {
            return false;
        }
    }

    getSucursales(){
        const url = URL_SERVICES + 'sucursals-api-all';
        return this.http.get(url);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
