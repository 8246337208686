import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {FormControl} from '@angular/forms';
import {CartService} from '../../services/cart.service';
import {WishlistService} from '../../services/wishlist.service';
import {CompareService} from '../../services/compare.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {SharedService} from '../../services/shared.service';
import {ProductService} from '../../../modules/shop/services/products.service';

export type ProductLayout = 'standard' | 'sidebar' | 'columnar' | 'quickview';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent {
    @Input() layout: ProductLayout;

    @Input() product: any;

    quantity: FormControl = new FormControl(1);

    addingToCart = false;
    addingToWishlist = false;
    addingToCompare = false;
    beforeUrl: string;

    constructor(@Inject(PLATFORM_ID) private platformId: any,
                private cart: CartService,
                private wishlist: WishlistService,
                private compare: CompareService,
                private sharedService: SharedService,
                private route: Router,
                private toastr: ToastrService,
                private _productService: ProductService) {
    }

    addToCart(): void {
        const quantity = this.quantity.value;

        if (this.product.stock < quantity) {
            this.toastr.error(`No disponible - No contamos con existencia de ${this.product.name}`);
            return;
        }

        if (!this.addingToCart && this.product && quantity > 0) {
            this.addingToCart = true;

            this.cart.add(this.product, quantity).subscribe({
                complete: () => {
                    this.addingToCart = false;
                    if (this.sharedService.beforeUrl === '/') {
                        this.route.navigate(['/']);
                    } else if (this.sharedService.beforeUrl === '/promociones') {
                        this.route.navigate(['/promociones']);
                    } else if (this.sharedService.beforeUrl.substring(0, 7) === '/search') {
                        const url = this.sharedService.beforeUrl;
                        this.route.navigateByUrl(`${url}`);
                    } else {
                        this.route.navigate(['/']);
                    }
                }
            });
        }
    }

    addToWishlist(): void {
        if (!this.addingToWishlist && this.product) {
            this.addingToWishlist = true;

            this.wishlist.add(this.product).subscribe({complete: () => this.addingToWishlist = false});
        }
    }

    addToCompare(): void {
        if (!this.addingToCompare && this.product) {
            this.addingToCompare = true;

            this.compare.add(this.product).subscribe({complete: () => this.addingToCompare = false});
        }
    }

    notifyChangeProduct(notify) {
        if (notify)
            this._productService.addNotification(this.product).subscribe({complete: () => this.product.notify = notify});
        else
            this._productService.destroyNotification(this.product).subscribe({complete: () => this.product.notify = notify});
    }
}
