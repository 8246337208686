<div class="container mt-4">
    <div class="products-view">
        <div class="block">
            <div *ngIf="productSearch" class="products-view__content">
                <div class="products-view__options mt-3">
                    <div class="view-options" [ngClass]="{
                        'view-options--offcanvas--always': offcanvas === 'always',
                        'view-options--offcanvas--mobile': offcanvas === 'mobile'
                    }">
                        <!-- <div class="view-options__filters-button">
                            <button type="button" class="filters-button" (click)="sidebar.open()">
                                <app-icon class="filters-button__icon" name="filters-16" size="16"></app-icon>
                                <span class="filters-button__title">Filters</span>
                                <span class="filters-button__counter" *ngIf="filtersCount > 0">{{ filtersCount }}</span>
                            </button>
                        </div> -->

                        <div class="view-options__legend">
                            Viendo {{ this.pagination.per_page > this.pagination.total ? this.pagination.to : this.pagination.per_page }}–{{ this.pagination.to }} de {{ this.pagination.total }} resultados
                        </div>
                        <div class="view-options__divider"></div>
                        <div class="view-options__control">
                            <label for="view-options-sort">Ordenar por</label>
                            <div>
                                <select [(ngModel)]="order" class="form-control form-control-sm" id="view-options-sort" (change)="onChangeSorting($event.target.value)">
                                    <option value="a-z">Nombre (A-Z)</option>
                                    <option value="z-a">Nombre (Z-A)</option>
                                    <option value="price-a">Precio ASC</option>
                                    <option value="price-z">Precio DESC</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="productSearch.length > 0" class="products-view__list products-list" [attr.data-layout]="layout != 'list' ? grid : layout" [attr.data-with-features]="layout == 'grid-with-features' ? 'true' : 'false'">
                    <div class="products-list__body">
                        <!-- <div *ngFor="let product of productSearch" class="products-list__item">
                            <app-product-card [product]="product"></app-product-card>
                        </div> -->
                        <div *ngFor="let product of productSearch | paginate: { itemsPerPage: 20, currentPage: pageActual, totalItems: this.pagination.total }" class="products-list__item">
                            <app-product-card [product]="product"></app-product-card>
                        </div>
                    </div>
                </div>

                <div class="products-view__pagination">
                    <pagination-controls class="rounded text-center rounded-circle" responsive="true" previousLabel="" nextLabel="" (pageChange)="newPage($event)">
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="!productSearch" class="products-view__empty">
    <div class="products-view__empty-title">No hay elementos coincidentes.</div>
    <div class="products-view__empty-subtitle">Intenta con otra busqueda</div>
    <button type="button" class="btn btn-primary btn-sm" routerLink="/">Ir a la Tienda</button>
</div>
