import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CompanyService} from './company.service';
import {URL_SERVICES} from '../../config/config';
import {BehaviorSubject, Observable} from 'rxjs';
import {SharedService} from './shared.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    public notifications: [];
    public productNotifications: [];
    public notificationsList: any;

    public pagination: any = {
        meta: {},
        links: {}
    };

    private notificationsSubject$: BehaviorSubject<any[]> = new BehaviorSubject(this.notifications);
    private productNotificationsSubject$: BehaviorSubject<any[]> = new BehaviorSubject(this.productNotifications);
    public notificationsListSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(this.notificationsList);

    readonly notifies$: Observable<any[]> = this.notificationsSubject$.asObservable();
    readonly productNotifies$: Observable<any[]> = this.productNotificationsSubject$.asObservable();

    constructor(@Inject(PLATFORM_ID)
                private platformId: any,
                private _companyService: CompanyService,
                private _http: HttpClient,
                private sharedService: SharedService) {
        this.load();
    }

    get notifies(): ReadonlyArray<any> {
        return this.notifications;
    }

    public load(): void {
        this.notifications = [];
        this.productNotifications = [];

        if (this._companyService.user) {
            const user = this._companyService.user.id;
            const url = URL_SERVICES + 'getNotifications/' + user;
            this._http.get(url).subscribe((notificationsEle: []) => {
                this.notifications = [];
                notificationsEle.map((singleNotification) => {
                    this.notifications.push(singleNotification);
                });
                this.notificationsSubject$.next(this.notifications);
            });

            const urlProduct = URL_SERVICES + 'notifications/' + user + '/product/notificados';
            this._http.get(urlProduct).subscribe((notifications: []) => {
                this.productNotifications = [];
                notifications.map(notification => {
                    this.productNotifications.push(notification);
                });
                this.productNotificationsSubject$.next(this.productNotifications);
            });
        }
    }

    async getAllNotifications() {
        const resp: any = await this.getAllList();
        if (resp.data) {
            this.notificationsList = resp.data;
            this.pagination = {links: resp.links, meta: resp.meta}
        } else {
            this.notificationsList = resp
        }
        this.notificationsListSubject$.next(this.notificationsList);
    }

    getAllList() {
        const pageActual = this.sharedService.pageActualNotification;
        const url = URL_SERVICES + `getAllNotifications` + `?page=${pageActual}`;

        return new Promise((resolve, reject) => {
            this._http.get(url)
                .toPromise()
                .then((resp: any) => {
                    resolve(resp);
                })
                .catch(this.handleError)
        })
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
