import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Product } from '../interfaces/product';
import { CartItem } from '../interfaces/cart-item';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { CompanyService } from './company.service';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICES } from '../../config/config';

interface CartTotal {
    title: string;
    price: number;
    type: 'shipping'|'fee'|'tax'|'other';
}

interface CartData {
    items: CartItem[];
    quantity: number;
    quantityProducts: number;
    subtotal: number;
    totals: CartTotal[];
    total: number;
}

@Injectable({
    providedIn: 'root'
})
export class CartService {
    public data: CartData = {
        items: [],
        quantity: 0,
        quantityProducts: 0,
        subtotal: 0,
        totals: [],
        total: 0
    };

    private itemsSubject$: BehaviorSubject<CartItem[]> = new BehaviorSubject(this.data.items);
    private quantitySubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.quantity);
    private quantityProductsSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.quantity);
    private subtotalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.subtotal);
    private totalsSubject$: BehaviorSubject<CartTotal[]> = new BehaviorSubject(this.data.totals);
    private totalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.total);
    private onAddingSubject$: Subject<Product> = new Subject();

    get items(): ReadonlyArray<CartItem> {
        return this.data.items;
    }

    get quantity(): number {
        return this.data.quantity;
    }

    get total(): number {
        return this.data.total;
    }

    readonly items$: Observable<CartItem[]> = this.itemsSubject$.asObservable();
    readonly quantity$: Observable<number> = this.quantitySubject$.asObservable();
    readonly quantityProducts$: Observable<number> = this.quantityProductsSubject$.asObservable();
    readonly subtotal$: Observable<number> = this.subtotalSubject$.asObservable();
    readonly totals$: Observable<CartTotal[]> = this.totalsSubject$.asObservable();
    readonly total$: Observable<number> = this.totalSubject$.asObservable();

    readonly onAdding$: Observable<Product> = this.onAddingSubject$.asObservable();

    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any,
        private _companyService: CompanyService,
        private _http: HttpClient
    ) {
        // if (isPlatformBrowser(this.platformId)) {
        //     this.load();
        // }

        this.load();
    }

    add(product:  Product | any, quantity: number, options: {name: string; value: string}[] = []): Observable<CartItem> {
        // timer only for demo
        return timer(1000).pipe(map(() => {
            this.onAddingSubject$.next(product);

            let item = this.items.find(eachItem => {
                if(eachItem.product){
                    if (eachItem.product.id !== product.id) {
                        return false;
                    }
                }

                return true;
            });

            if (item) {
                item.quantity += quantity;
            } else {
                item = {product, quantity, options};
                this.data.items.push(item);
            }

            this.save(product, quantity);
            this.calc();

            return item;
        }));
    }

    update(updates: {item: CartItem, quantity: number}[]): Observable<void> {
        // timer only for demo
        return timer(1000).pipe(map(() => {
            updates.forEach(update => {
                const item = this.items.find(eachItem => eachItem === update.item);

                if (item) {
                    item.quantity = update.quantity;
                }
            });
            this.updateCart(updates[0].item.product, updates[0].item.quantity);
            this.calc();
        }));
    }

    remove(item: CartItem): Observable<void> {
        // timer only for demo
        const user = this._companyService.user.id;

        const url = URL_SERVICES + 'deleteToCar';
        const data = {
            user_id: user,
            product_id: item.product.id
        }

        return this._http.post( url, data ).pipe(map(() => {
            this.data.items = this.data.items.filter(eachItem => eachItem !== item);

            this.save();
            this.calc();
        }));

    }

    removeAll(): Observable<void> {
        // timer only for demo
        const user = this._companyService.user.id;

        const url = URL_SERVICES + 'deleteToAllCar';
        const data = {
            user_id: user,
        }

        return this._http.post( url, data ).pipe(map(() => {
            this.data.items = [];

            this.save();
            this.calc();
        }));
    }

    private calc(): void {
        let quantity = 0;
        let quantityProducts = 0;
        let subtotal = 0;
        let iva = 0;

        this.data.items.forEach(item => {

            if(item.product){
                let priceProduct = item.product.price;
                if(item.product.temporada_oferta === 'S' && item.product.pieces_offer <= item.quantity ) {
                    priceProduct = +item.product.price_offer / (1 + +item.product.iva);
                }

                quantity += item.quantity;
                subtotal += priceProduct * item.quantity;
                iva += +priceProduct * +item.quantity * +item.product.iva;
            }
        });

        const totals: CartTotal[] = [];

        totals.push({
            title: 'IVA',
            price: iva,
            type: 'tax'
        });

        const total = subtotal + iva;

        this.data.quantity = quantity;
        this.data.quantityProducts = this.data.items.length;
        this.data.subtotal = subtotal;
        this.data.totals = totals;
        this.data.total = total;

        this.itemsSubject$.next(this.data.items);
        this.quantitySubject$.next(this.data.quantity);
        this.quantityProductsSubject$.next(this.data.quantityProducts);
        this.subtotalSubject$.next(this.data.subtotal);
        this.totalsSubject$.next(this.data.totals);
        this.totalSubject$.next(this.data.total);
    }

    public removeAllCart() {

        this.data.items = [];
        const user = this._companyService.user.id;

        const url = URL_SERVICES + 'deleteToAllCar';
        const item = {
            user_id: user
        }

        this._http.post( url, item ).subscribe( (product) => {
            this.save();
            this.calc();
        });
      }

    private save(product?, quantity?): void {
        // localStorage.setItem('cartItems', JSON.stringify(this.data.items));
        const user = this._companyService.user.id;

        const url = URL_SERVICES + 'addToCar';
        // url += '?token=' + this._companyService.token;
        if(product) {
            const item = {
                user_id: user,
                product_id: product.id,
                quantity
            }

            this._http.post( url, item ).subscribe( (product) => {
            });
        }
    }

    private updateCart(product?, quantity?): void {
        // localStorage.setItem('cartItems', JSON.stringify(this.data.items));
        const user = this._companyService.user.id;

        const url = URL_SERVICES + 'updateToCar';
        // url += '?token=' + this._companyService.token;
        if(product) {
            const item = {
                user_id: user,
                product_id: product.id,
                quantity
            }


            this._http.post( url, item ).subscribe( (product) => {
                this.calc();
            });
        }
    }

    public load(): void {
        const items = localStorage.getItem('cartItems');
        this.data.items = [];

        if(this._companyService.user) {
            const user = this._companyService.user.id;

            const url = URL_SERVICES + 'getCar/' + user;

            this._http.get( url ).subscribe( (products: any[]) => {
                this.data.items = [];
                products.map( (singleProduct) => {
                    this.data.items.push(singleProduct);
                });
                this.calc();
            });
        }

    }
}
