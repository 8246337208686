import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, filter} from 'rxjs/operators';
import {Router} from '@angular/router';
import {URL_SERVICES} from '../../config/config';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CompanyService} from './company.service';

@Injectable({
    providedIn: 'root'
})

export class OrderService {

    // Array
    public OrderDetails;
    public order;
    public nroOrder: string;
    public payMethod: string;
    public sucursal: string;

    constructor(
        private router: Router,
        private _companyService: CompanyService,
        private _http: HttpClient
    ) {
    }

    // Get order items
    public getOrderItems() {
        return this.OrderDetails;
    }

    public getOrderDetail() {
        return this.order;
    }

    getPaymentMethod() {

        let url = URL_SERVICES + 'get-payment-methods';
        url += '?token=' + this._companyService.token;

        return this._http.get(url);

    }

    getInfoOrders() {
        const user = this._companyService.user.id;

        const url = URL_SERVICES + 'get-info-orders/' + user;

        return this._http.get(url);
    }

    // Create order
    public createOrder(product: any, amount: any, addressPrincipal?: boolean, formBillingAddress?, paymentMethod?, comment?): Promise<any> {

        product.map((singleProduct) => {
            if (singleProduct.product.temporada_oferta === 'S' && singleProduct.product.price_offer > 0 && singleProduct.product.pieces_offer <= singleProduct.quantity) {
                return singleProduct.product.price = singleProduct.product.price_offer / (1 + +singleProduct.product.iva);
            } else {
                return singleProduct.product.price = singleProduct.product.price;
            }
        });
        const item = {
            shippingDetails: addressPrincipal,
            product,
            paymentMethod,
            orderId: Math.random().toString(10).substring(10),
            totalAmount: amount,
            comment,
            // userId: this.userService.user.id,
            clientId: this._companyService.user.id,
            billingAddress: formBillingAddress,
            date: Date.now(),
        };
        this.OrderDetails = item;

        let url = URL_SERVICES + 'orders-api';
        url += '?token=' + this._companyService.token;

        return this._http.post(url, item)
            .toPromise()
            .catch(err => console.error(err)
            );

    }

    getOrders() {

        const user = this._companyService.user.id;

        let url = URL_SERVICES + 'orders-by-users/' + user;
        url += '?token=' + this._companyService.token;

        return this._http.get(url);

    }

    getRents(pageActual = 1) {
        const user = this._companyService.user.id;
        let url = URL_SERVICES + 'rents-by-users/' + user + `?page=${pageActual}`;
        url += '&token=' + this._companyService.token;
        return this._http.get(url);
    }

    getOrder(order) {
        let url = URL_SERVICES + 'orders-api/' + order;
        url += '?token=' + this._companyService.token;
        return this._http.get(url);
    }

    getInvoices(order) {

        let url = URL_SERVICES + 'orders-invoices-api/' + order;
        url += '?token=' + this._companyService.token;

        return this._http.get(url);
    }

    getAllInvoicesByUser(pageActual) {
        const user = this._companyService.user.id;
        const url = URL_SERVICES + 'getInvoiceByUser/' + user + `?page=${pageActual}`;
        return this._http.get(url);
    }

    downloadXml(order, cfdi) {

        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/xml');

        let url = URL_SERVICES + 'download-xml/' + order + '/' + cfdi;
        return this._http.get(url, {headers: headers, responseType: 'blob'});
    }

    downloadPdf(order, cfdi, shoppingId) {

        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');

        let url = URL_SERVICES + 'download-pdf/' + order + '/' + cfdi + '/' + shoppingId;
        return this._http.get(url, {headers: headers, responseType: 'blob'});
    }

    deleteOrder(sale) {
        const token = this._companyService.token;

        let url = URL_SERVICES + 'orders-api/' + sale;
        url += '?token=' + token;

        return this._http.delete(url);
    }

}
