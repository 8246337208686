<div class="block bg-login">
    <div class="container">
        <div class="row">
            <div class="col-md-6 d-flex">
                <div class="card flex-grow-1 mb-md-0 login-card-bg" style="top:40%">
                    <div class="card-body">
                        <div class="text-center">
                            <img src="../../../../../assets/brand/logo_login.png" alt="">
                        </div>
                        <form [formGroup]="formaLogin" (ngSubmit)="loginClient()">
                            <div class="form-group">
                                <input type="email" formControlName="email" class="form-control" placeholder="Ingrese e-mail">
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <input [type]="inputType" formControlName="password" class="form-control" placeholder="Contraseña">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1" *ngIf="visible">
                                                <i (click)="viewPassword()" class="pointer fa fa-eye" aria-hidden="true"></i>
                                            </span>
                                        <span class="input-group-text" id="basic-addon2" *ngIf="!visible">
                                                <i (click)="viewPassword()" class="pointer fa fa-eye-slash" aria-hidden="true"></i>
                                            </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="custom-control custom-checkbox">
                                    <input class="custom-control-input" type="checkbox" id="remember-me" (change)="disabledIngreso()" tabindex="3" checked/>
                                    <label class="custom-control-label" for="remember-me" style="color: white;">
                                        He leído completamente los <strong><span (click)="term()" style="color:#25D366; cursor: pointer;">Términos y Condiciones</span></strong>, Estoy de acuerdo con lo que se plantea en los mismos.
                                    </label>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-success btn-lg btn-block mt-2" [disabled]="ingreso">Ingresar</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>