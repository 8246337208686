import { NgModule } from '@angular/core';

// modules (angular)
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// modules (third-party)
import { CarouselModule } from 'ngx-owl-carousel-o';

// modules
import { BlocksModule } from '../blocks/blocks.module';
import { SharedModule } from '../../shared/shared.module';
import { ShopRoutingModule } from './shop-routing.module';
import { WidgetsModule } from '../widgets/widgets.module';
import { NgxPaginationModule } from 'ngx-pagination';

// components
import { ProductsViewComponent } from './components/products-view/products-view.component';
import { ProductTabsComponent } from './components/product-tabs/product-tabs.component';
import { ShopSidebarComponent } from './components/shop-sidebar/shop-sidebar.component';

// pages
import { PageCartComponent } from './pages/page-cart/page-cart.component';
import { PageCartMasiveComponent } from './pages/page-cart-masive/page-cart.component';
import { PageCategoryComponent } from './pages/page-category/page-category.component';
import { PageCheckoutComponent } from './pages/page-checkout/page-checkout.component';
import { PageCompareComponent } from './pages/page-compare/page-compare.component';
import { PageProductComponent } from './pages/page-product/page-product.component';
import { PageTrackOrderComponent } from './pages/page-track-order/page-track-order.component';
import { PageWishlistComponent } from './pages/page-wishlist/page-wishlist.component';
import { ProductSidebarComponent } from './components/product-sidebar/product-sidebar.component';
import { PageOrderSuccessComponent } from './pages/page-order-success/page-order-success.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { PageTrainingComponent } from './pages/page-training/page-training.component';
import { PageViewAltComponent } from './components/page-view-alt/page-view-alt.component';
import { ProductViewAltComponent } from './components/product-view-alt/product-view-alt.component';
import { PageSearchComponent } from './pages/page-search/page-search.component';
import { PagePromotionComponent } from './pages/page-promotions/page-promotion.component';
import { PageFeriaComponent } from './pages/page-feria/page-feria.component';

//modals
import { ModalCheckoutComponent } from './components/modal-checkout/modal-checkout.component';
import { ModalAddressComponent } from './components/modal-address/modal-address.component';
import {PageNotificationComponent} from './pages/page-notification/page-notification.component';
import { PageAdvancedSearchComponent } from './pages/page-advanced-search/page-advanced-search.component';
import {PageTradeFairComercialComponent} from './pages/page-trade-fair-comercial/page-trade-fair-comercial.component';
import { PageNewProductsComponentComponent } from './pages/page-new-products-component/page-new-products-component.component';

@NgModule({
    declarations: [
        // components
        ProductsViewComponent,
        ProductTabsComponent,
        ShopSidebarComponent,
        // pages
        PageCartComponent,
        PageCartMasiveComponent,
        PageCategoryComponent,
        PageTrainingComponent,
        PageCheckoutComponent,
        PageCompareComponent,
        PageProductComponent,
        PageTrackOrderComponent,
        PageWishlistComponent,
        ProductSidebarComponent,
        PageOrderSuccessComponent,
        PageViewAltComponent,
        ProductViewAltComponent,
        ModalCheckoutComponent,
        ModalAddressComponent,
        PageSearchComponent,
        PagePromotionComponent,
        PageFeriaComponent,
        PageNotificationComponent,
        PageAdvancedSearchComponent,
        PageTradeFairComercialComponent,
        PageNewProductsComponentComponent
    ],
    imports: [
        // modules (angular)
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
       // OrderModule,
        // modules (third-party)
        CarouselModule,
        NgxPaginationModule,
        // modules
        BlocksModule,
        SharedModule,
        ShopRoutingModule,
        WidgetsModule,
        PipesModule
    ],
    exports: [
        ProductsViewComponent,
        ProductViewAltComponent,
        ShopSidebarComponent,
    ]
})
export class ShopModule { }
