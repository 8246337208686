import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShopService } from '../../../../shared/api/shop.service';
import { Observable } from 'rxjs';
import { ProductService } from '../../services/products.service';

@Component({
    selector: 'app-page-product',
    templateUrl: './page-product.component.html',
    styleUrls: ['./page-product.component.scss']
})
export class PageProductComponent implements OnInit {
    relatedProducts$: Observable<any>;
    noSpec: 'Sin Especificaciones'
    relatedProducts;

    product: any;
    // product: Product;
    layout: 'standard'|'columnar'|'sidebar' = 'standard';
    sidebarPosition: 'start'|'end' = 'start'; // For LTR scripts "start" is "left" and "end" is "right"

    constructor(
        private shop: ShopService,
        private route: ActivatedRoute,
        private _productService: ProductService,
    ) {
        this.route.params.subscribe(async params => {
            if (params) {
                this.getProductInfo(params.productSlug);
            }
        })
    }

    ngOnInit(): void {
    }

    async getProductInfo(slug: string) {
        const resp = await this._productService.getProductDetail(slug);
        if (resp) {
            this.product = resp.items;
            this.product.priceWithIva = this.product.price + this.product.price * this.product.iva;
            this.relatedProducts = this._productService.getRelatedProducts(this.product.id)
            .subscribe( (productsRelated) => {
                productsRelated.map( (productRelated) => {
                    productRelated.priceWithIva = productRelated.price + (productRelated.price * productRelated.iva);
                    return productRelated;
                  });
                this.relatedProducts = productsRelated;
            });
        }
    }
    async getRelated(categoryId) {
        const resp = await this._productService.getRelatedProducts(categoryId);
    }
}
