import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {CompanyService} from './company.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {URL_SERVICES} from '../../config/config';
import {BehaviorSubject, Observable} from 'rxjs';
import {SendFilterModel} from '../../modules/shop/pages/page-advanced-search/filter.model';
import {SharedService} from './shared.service';
import {filter} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FilterService {

    public labsFromCarousel: any = false;

    public filters: [];

    public filtersSubject$: BehaviorSubject<any[]> = new BehaviorSubject(this.filters);

    constructor(@Inject(PLATFORM_ID)
                private platformId: any,
                private _companyService: CompanyService,
                private _http: HttpClient,
                private sharedService: SharedService) {
        this.load();
    }

    public load(): void {
        this.filters = [];

        const url = URL_SERVICES + 'filters-by-products';

        this._http.get(url).subscribe((filtersEle: []) => {
            this.filters = filtersEle;
            this.filtersSubject$.next(this.filters);
        });
    }

    getProductAdvancedSearch(sucursal, limit: number, filters: SendFilterModel, sort: string = 'a-z') {
        const user = this._companyService.user.id;
        const params = new HttpParams()
            .set('substances', JSON.stringify(filters.substances))
            .set('labs', JSON.stringify(filters.labs))
            .set('presentations', JSON.stringify(filters.presentations));
        const pageActual = this.sharedService.pageActualAdvancedSearch;
        const url = URL_SERVICES + 'get-products-advanced_search/' + user + '/' + sucursal + '/' + sort + `?page=${pageActual}`;
        return new Promise((resolve, reject) => {
            this._http.get(url, {params})
                .toPromise()
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => console.error(err)
                )
        })
    }

    setLabsFromCarousel(labs){
        this.labsFromCarousel = labs;
    }

    getLabsFromCarousel(){
        return this.labsFromCarousel;
    }
}
