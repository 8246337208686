<div class="totop__body">
    <div class="totop__start">
        <button type="button" class="totop__button" (click)="onClick()">
            <app-icon name="arrow-rounded-up-13x8" size="13x8"></app-icon>
        </button>
    </div>
    <div class="totop__container container"></div>
    <div class="totop__end">

    </div>
</div>