import { NgModule } from '@angular/core';
import { ImagePipe } from './image.pipe';
import { OrderByPipe } from './order-by.pipe';

@NgModule({
  imports:[],
  declarations: [
    ImagePipe,
    OrderByPipe
  ],
  exports:[
    ImagePipe,
    OrderByPipe
  ]
})
export class PipesModule { }
