import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Layout} from '../../components/products-view/products-view.component';
import {Subscription} from 'rxjs';
import {FilterService} from '../../../../shared/services/filter.service';
import {FilterModel, LabModel, PresentationModel, SendFilterModel, SubstanceModel} from './filter.model';
import {SharedService} from '../../../../shared/services/shared.service';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {DirectionService} from '../../../../shared/services/direction.service';
import {DesignService} from '../../../../shared/services/design.service';

@Component({
    selector: 'app-page-advanced-search',
    templateUrl: './page-advanced-search.component.html',
    styleUrls: ['./page-advanced-search.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PageAdvancedSearchComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() lab: any;
    productList: any = [];
    @Input() layout: Layout = 'grid';
    @Input() grid: 'grid-3-sidebar' | 'grid-4-full' | 'grid-5-full' = 'grid-3-sidebar';
    @Input() offcanvas: 'always' | 'mobile' = 'mobile';
    order = 'a-z';
    pagination = {
        last_page: 1,
        current_page: 1,
        path: '',
        per_page: 1,
        to: 1,
        total: 1
    }
    isHiddenSub = false;
    isHiddenLab = true;
    isHiddenPres = true;
    showProduct = false;

    filters: FilterModel;
    substances: SubstanceModel[];
    labs: LabModel[];
    presentations: PresentationModel[];

    _nameLab: any = false;
    _nameLabLength: any = 0;

    sendFilters: SendFilterModel;
    sendSubstances: any[] = [];
    sendLabs: any[] = [];
    sendPresentations: any[] = [];

    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        autoplay: true,
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 3
            }
        },
        nav: false,
        rtl: this.direction.isRTL()
    }
    products = [];

    subscription: Subscription;

    constructor(public filterService: FilterService,
                public sharedService: SharedService,
                private direction: DirectionService,
                private _designService: DesignService) {
        this.getFilters();

        this.sendFilters = {
            substances: this.sendSubstances,
            labs: this.sendLabs,
            presentations: this.sendPresentations,
        }
        this.getProductAdvancedSearch();
    }

    async getFilters() {
        await this.filterService.load();

        this.subscription = this.filterService.filtersSubject$.subscribe((filters: any) => {
            if (filters) {
                this.filters = filters;
                this.substances = filters.substances;
                this.labs = filters.labs;
                this.presentations = filters.presentations;
            }
        })
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        const labCarousel = this.filterService.getLabsFromCarousel();
        if (labCarousel) {
            setTimeout(() => {
                this.sendLabs.push(labCarousel.toString());
                this.getProductAdvancedSearch();
            }, 300);
            this.filterService.setLabsFromCarousel(false);
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onChangeSubstance(event) {
        if (this.sendFilters.substances.length > 0 || this.sendFilters.labs.length > 0 || this.sendFilters.presentations.length > 0) {
            if (!event.target.value) {
                this.substances = this.filters.substances.filter((substance: SubstanceModel) => {
                    return this.productList.substances.includes(substance.description);
                });
                setTimeout(() => {
                    this.checkAllElements();
                }, 300);
                return;
            }

            const val = event.target.value.toUpperCase();
            this.substances = this.filters.substances.filter((substance: SubstanceModel) => {
                return this.productList.substances.includes(substance.description);
            });
            this.substances = this.substances.filter((substance: SubstanceModel) => {
                return substance.description.toUpperCase().indexOf(val) >= 0;
            });
        } else {
            if (!event.target.value) {
                this.substances = this.filters.substances;
                setTimeout(() => {
                    this.checkAllElements();
                }, 300);
                return;
            }

            const val = event.target.value.toUpperCase();
            this.substances = this.filters.substances.filter((substance: SubstanceModel) => {
                return substance.description.toUpperCase().indexOf(val) >= 0;
            });
        }
        setTimeout(() => {
            this.checkAllElements();
        }, 300);
    }

    onChangeLab(event) {
        if (this.sendFilters.substances.length > 0 || this.sendFilters.labs.length > 0 || this.sendFilters.presentations.length > 0) {
            if (!event.target.value) {
                this.labs = this.filters.labs.filter((lab: LabModel) => {
                    return this.productList.labs.includes(lab.id.toString());
                });
                setTimeout(() => {
                    this.checkAllElements();
                }, 300);
                return;
            }
            const val = event.target.value.toUpperCase();
            this.labs = this.filters.labs.filter((lab: LabModel) => {
                return this.productList.labs.includes(lab.id.toString());
            });
            this.labs = this.labs.filter((lab: LabModel) => {
                return lab.name.toUpperCase().indexOf(val) >= 0;
            });
        } else {
            if (!event.target.value) {
                this.labs = this.filters.labs;
                setTimeout(() => {
                    this.checkAllElements();
                }, 300);
                return;
            }

            const val = event.target.value.toUpperCase();
            this.labs = this.filters.labs.filter((lab: LabModel) => {
                return lab.name.toUpperCase().indexOf(val) >= 0;
            });
        }

        setTimeout(() => {
            this.checkAllElements();
        }, 300);
    }

    onChangePresentation(event) {
        if (this.sendFilters.substances.length > 0 || this.sendFilters.labs.length > 0 || this.sendFilters.presentations.length > 0) {
            if (!event.target.value) {
                this.presentations = this.filters.presentations.filter((presentation: PresentationModel) => {
                    return this.productList.presentations.includes(presentation.description);
                });
                setTimeout(() => {
                    this.checkAllElements();
                }, 300);
                return;
            }

            const val = event.target.value.toUpperCase();
            this.presentations = this.filters.presentations.filter((presentation: PresentationModel) => {
                return this.productList.presentations.includes(presentation.description);
            });
            this.presentations = this.presentations.filter((presentation: PresentationModel) => {
                return presentation.description.toUpperCase().indexOf(val) >= 0;
            });
        } else {
            if (!event.target.value) {
                this.presentations = this.filters.presentations;
                setTimeout(() => {
                    this.checkAllElements();
                }, 300);
                return;
            }

            const val = event.target.value.toUpperCase();
            this.presentations = this.filters.presentations.filter((presentation: PresentationModel) => {
                return presentation.description.toUpperCase().indexOf(val) >= 0;
            });
        }

        setTimeout(() => {
            this.checkAllElements();
        }, 300);
    }

    checkAllElements() {
        this.sendSubstances.forEach(value => {
            document.getElementById('substances').querySelectorAll('input[type="checkbox"]').forEach(elem => {
                if (elem.id === value)
                    (elem as HTMLInputElement | null).checked = true;
            })
        });
        this.sendLabs.forEach(value => {
            document.getElementById('labs').querySelectorAll('input[type="checkbox"]').forEach(elem => {
                if (elem.id === value)
                    (elem as HTMLInputElement | null).checked = true;
            })
        });
        this.sendPresentations.forEach(value => {
            document.getElementById('presentations').querySelectorAll('input[type="checkbox"]').forEach(elem => {
                if (elem.id === value)
                    (elem as HTMLInputElement | null).checked = true;
            })
        });
    }

    onFilters(event, type) {
        switch (type) {
            case 'Substance':
                if (event.target.checked)
                    this.sendSubstances.push(event.target.value);
                else
                    this.sendSubstances = this.sendSubstances.filter((substance) => substance !== event.target.value);
                break;
            case 'Lab':
                if (event.target.checked)
                    this.sendLabs.push(event.target.value);
                else
                    this.sendLabs = this.sendLabs.filter((lab) => lab !== event.target.value);
                break;
            case 'Presentation':
                if (event.target.checked)
                    this.sendPresentations.push(event.target.value);
                else
                    this.sendPresentations = this.sendPresentations.filter((presentation) => presentation !== event.target.value);
                break;
        }

        this.sendFilters = {
            substances: this.sendSubstances,
            labs: this.sendLabs,
            presentations: this.sendPresentations
        };
        this.getProductAdvancedSearch();
    }

    async getProductAdvancedSearch() {
        const products: any = await this.filterService.getProductAdvancedSearch(1, 2000, this.sendFilters, this.order);
        products.data.map((product) => {
            product.priceWithIva = product.price + (product.price * product.iva);
            return product;
        });
        this.productList = products;

        this.showProduct = this.productList.data.length > 0;

        this.pagination = this.productList.meta ? this.productList.meta : {last_page: 1, current_page: 1, path: ''}

        if (this.sendFilters.substances.length > 0 || this.sendFilters.labs.length > 0 || this.sendFilters.presentations.length > 0) {
            if (this.productList.substances.length > 0) {
                this.substances = this.filters.substances.filter((substance: SubstanceModel) => {
                    return this.productList.substances.includes(substance.description);
                });
            } else {
                this.substances = [];
            }
            if (this.productList.labs.length > 0) {
                this.labs = this.filters.labs.filter((lab: LabModel) => {
                    return this.productList.labs.includes(lab.id.toString());
                });
                this._designService.getAllCarouselList(this.labs[0].id).subscribe((lab: any) => {
                    lab.carousel.map((product) => {
                        product.priceWithIva = product.price + (product.price * product.iva);
                        return product;
                    });
                    this.products = lab.carousel;

                    if (this.products.length > 0) {
                        this._nameLab = this.labs[0].name;
                        this._nameLabLength = (this._nameLab.replace(/\s{2,}/g, ' ').trim()).length * 11;
                    }
                });
            } else {
                this.labs = [];
                this._nameLab = false;
            }
            if (this.productList.presentations.length > 0) {
                this.presentations = this.filters.presentations.filter((presentation: PresentationModel) => {
                    return this.productList.presentations.includes(presentation.description);
                });
            } else {
                this.presentations = [];
            }

            document.getElementById('filters').querySelectorAll('.advanced_search_input').forEach(value => {
                (value as HTMLInputElement | null).value = '';
            });
        } else {
            this.substances = this.filters.substances;
            this.labs = this.filters.labs;
            this.presentations = this.filters.presentations;
            this._nameLab = false;
            this._nameLabLength = 0;
        }

        setTimeout(() => {
            this.checkAllElements();
        }, 300);
    }

    newPage(event) {
        this.sharedService.pageActualAdvancedSearch = event;
        this.getProductAdvancedSearch();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    setLayout(value: Layout): void {
        this.layout = value;
    }

    onChangeSorting(event) {
        this.sharedService.pageActualAdvancedSearch = 1;
        this.order = event;
        this.getProductAdvancedSearch();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    onResetFilters() {
        document.getElementById('filters').querySelectorAll('input[type="checkbox"]:checked').forEach(value => {
            (value as HTMLInputElement | null).checked = false;
        });
        this.sendSubstances = [];
        this.sendLabs = [];
        this.sendPresentations = [];
        this.sendFilters = {
            substances: this.sendSubstances,
            labs: this.sendLabs,
            presentations: this.sendPresentations
        };
        this.getProductAdvancedSearch();
    }
}
