<div class="dropcart">
    <div class="dropcart__products-list">
        <div *ngFor="let item of notification.notifies$|async" class="dropcart__product">
            <!-- <div class="dropcart__product"> -->
            <div class="dropcart__product-image product-image">
                <a class="product-image__body" href="{{item.url}}">
                    <img class="product-image__img" [src]="item.image" alt="">
                </a>
            </div>
            <div class="dropcart__product-info">
                 <div class="dropcart__product-name">
                    <a [routerLink]="">{{ item.title }}</a>
                </div>
                <div class="dropcart__product-meta">
                    <span class="dropcart__product-quantity">
                        {{ item.message }}
                    </span>
                </div>
            </div>
        </div>
        <div class="dropcart__buttons">
            <div class="row">
                <div class="col-12 pr-0">
                    <a class="btn btn-block mr-0 text-white btn-success" [routerLink]="root.notifications()" (click)="closeMenu.emit()">Ver Todas</a>
                </div>
            </div>
        </div>
    </div>
</div>
