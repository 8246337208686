import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CompanyService } from '../../../../shared/services/company.service';

@Component({
    selector: 'app-account-menu',
    templateUrl: './account-menu.component.html',
    styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent implements OnInit {
    @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();
    forma: FormGroup;
    email: string;
    loginForm: boolean = true;
    link = '';
    constructor(
        public _companyService: CompanyService
    ) { 
        if(this._companyService.user){
            if(this._companyService.user.role === 'Empresa' || this._companyService.user.role === 'Cliente') {
              this.link = '/cuenta/dashboard';
            }
            // else{
            //   this.link = '/empresa/dashboard';
            // }
        }
    }

    ngOnInit() {

        this.forma = new FormGroup({
          email: new FormControl( null , Validators.required ),
          password: new FormControl( null , Validators.required ),
        });

      }

      back() {
        this.loginForm = true;
      }

      recover() {
        this.loginForm = false;
      }

      login() {

        if ( this.forma.invalid ) {
          return;
        }
        // const user = new User(null, null, this.forma.value.email, this.forma.value.password);

        this._companyService.login( this.forma.value );

      }
}
