<!-- <div class="block block-products block-products--layout--large-first">
    <div class="container">
        <div class="block-header">
            <h3 class="block-header__title">Productos</h3>
            <div class="block-header__divider"></div>
            <div class="products-view__loader"></div>
        </div> -->
<div class="products-view">
    <div class="block">
        <div *ngIf="productList.length" class="products-view__content">
            <div class="products-view__options mt-3">
                <div class="col-12 mt-3 mb-3 px-0" *ngIf="byCategory">
                    <img [src]="imageCategory" class="img-responsive fit-image2" alt="">
                </div>
                <div class="view-options" [ngClass]="{
                            'view-options--offcanvas--always': offcanvas === 'always',
                            'view-options--offcanvas--mobile': offcanvas === 'mobile'
                        }">
                    <!-- <div class="view-options__filters-button">
                                <button type="button" class="filters-button" (click)="sidebar.open()">
                                    <app-icon class="filters-button__icon" name="filters-16" size="16"></app-icon>
                                    <span class="filters-button__title">Filters</span>
                                    <span class="filters-button__counter" *ngIf="filtersCount > 0">{{ filtersCount }}</span>
                                </button>
                            </div> -->

                    <div class="view-options__layout">
                        <div class="layout-switcher">
                            <div class="layout-switcher__list">
                                <button title="Grid" type="button" class="layout-switcher__button" [ngClass]="{'layout-switcher__button--active': layout === 'grid'}" (click)="setLayout('grid')">

                                    <app-icon name="layout-grid-16x16" size="16"></app-icon>
                                </button>
                                <!-- <button title="Grid With Features" type="button" class="layout-switcher__button" [ngClass]="{'layout-switcher__button--active': layout === 'grid-with-features'}" (click)="setLayout('grid-with-features')">

                                    <app-icon name="layout-grid-with-details-16x16" size="16"></app-icon>
                                </button> -->
                                <button title="List" type="button" class="layout-switcher__button" [ngClass]="{'layout-switcher__button--active': layout === 'list'}" (click)="setLayout('list')">

                                    <app-icon name="layout-list-16x16" size="16"></app-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="view-options__legend">
                        Viendo {{ this.pagination.per_page > this.pagination.total ? this.pagination.to : this.pagination.per_page }}–{{ this.pagination.to }} de {{ this.pagination.total }} resultados
                    </div>
                    <div class="view-options__divider"></div>
                    <div class="view-options__control">
                        <label for="view-options-sort">Ordenar por</label>
                        <div>
                            <select [(ngModel)]="order" class="form-control form-control-sm" id="view-options-sort" (change)="onChangeSorting($event.target.value)">
                                <option value="a-z">Nombre (A-Z)</option>
                                <option value="z-a">Nombre (Z-A)</option>
                                <option value="price-a">Precio ASC</option>
                                <option value="price-z">Precio DESC</option>
                                <option value="sustancias">Sustancias</option>
                                <option value="department">Laboratorio</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="productList.length > 0" class="products-view__list products-list" [attr.data-layout]="layout != 'list' ? grid : layout" [attr.data-with-features]="layout == 'grid-with-features' ? 'true' : 'false'">
                <div class="products-list__body">
                    <div *ngFor="let product of productList | paginate: { itemsPerPage: 21, currentPage: sharedService.pageActual, totalItems: this.pagination.total }" class="products-list__item">
                        <app-product-card [product]="product" [order]="order" [pieces]="product.pieces"></app-product-card>
                    </div>
                </div>
            </div>

            <div class="products-view__pagination">
                <pagination-controls class="rounded text-center rounded-circle" responsive="true" previousLabel="" nextLabel="" (pageChange)="newPage($event)">
                </pagination-controls>
                <!-- <app-pagination formControlName="page" (pageChange)="newPage($event)" [siblings]="pagination.current_page" [total]="pagination.last_page"></app-pagination> -->
            </div>
        </div>
    </div>
</div>


<div *ngIf="!productList.length" class="products-view__empty">
    <div class="products-view__empty-title">No hay elementos coincidentes.</div>
    <div class="products-view__empty-subtitle">Intenta restablecer los filtros</div>
    <button type="button" class="btn btn-primary btn-sm" (click)="resetFilters()">Restablecer filtros</button>
</div>
<!-- </div>
</div> -->
