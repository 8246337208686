import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ShopService} from 'src/app/shared/api/shop.service';
import {HttpClient} from '@angular/common/http';
import {URL_SERVICES} from '../../../config/config';
import {CompanyService} from '../../../shared/services/company.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {SharedService} from '../../../shared/services/shared.service';
import {Product} from '../../../shared/interfaces/product';

@Injectable({
    providedIn: 'root'
})
export class ProductService {
    URL_API = URL_SERVICES;
    URL_API_PROD = this.URL_API + 'products-api/'
    URL_API_PROD2 = this.URL_API + 'products-api'
    private productList: any;
    private productListTradeFair: any;
    private newProductsList: any;
    private recentList: any;
    private popularList: any;
    public popularProductsResource: BehaviorSubject<any> = new BehaviorSubject<any>(this.popularList);
    public recentProductsResource: BehaviorSubject<any> = new BehaviorSubject<any>(this.recentList);
    public productResource: BehaviorSubject<any> = new BehaviorSubject<any>(this.productList);
    public productResourceOffer: BehaviorSubject<any> = new BehaviorSubject<any>(this.productList);
    public productResourceTradeFair: BehaviorSubject<any> = new BehaviorSubject<any>(this.productListTradeFair);
    public newProductsResource: BehaviorSubject<any> = new BehaviorSubject<any>(this.newProductsList);
    public pagination: any = {
        meta: {},
        links: {}
    };
    public paginationTradeFair: any = {
        meta: {},
        links: {}
    };
    public paginationNewProducts: any = {
        meta: {},
        links: {}
    };

    constructor(
        private _shopService: ShopService,
        private _toastr: ToastrService,
        private sharedService: SharedService,
        private _companyService: CompanyService,
        private http: HttpClient,
        private router: Router
    ) {
        this.getProducts();
        this.getRecentProduct();
        this.getPopularProduct();
    }

    public createProduct(product, images) {

        let url = URL_SERVICES + 'products-api';
        url += '?token=' + this._companyService.token;

        const data = product;

        data.images = JSON.stringify(images);
        data.user_id = this._companyService.user.id;

        return this.http.post(url, data)
            .subscribe((resp: any) => {
                this._toastr.success('Producto agregado correctamente.');
                this.router.navigate(['/empresa/productos']);
                return resp;
            }, (error: any) => {
                this._toastr.error(`Error creando el producto`);
            });
    }

    async getRecentProduct() {
        const resp: any = await this.getDataRecentProducts();

        if (resp) {
            this.recentList = resp.data.slice(-5);
            this.recentProductsResource.next(this.recentList);
        }
    }

    async getPopularProduct() {
        const resp: any = await this.getDataPopularProducts();

        if (resp) {
            this.popularList = resp.data.slice(-6);
            this.popularProductsResource.next(this.popularList);
        }
    }

    async getProducts(slug?, url?, sort?) {
        const resp: any = url ? await this.getProductFromUrl(url) : await this.getProductsList(slug ? slug : null, sort ? sort : 'a-z');
        if (resp.data) {
            this.productList = resp.data;
            this.pagination = {links: resp.links, meta: resp.meta}
        } else {
            this.productList = resp
        }
        this.productResource.next(this.productList);
    }

    async getProductsOffer(slug?, url?, sort?) {
        const resp: any = url ? await this.getProductFromUrl(url) : await this.getProductsList(slug ? slug : null, sort ? sort : 'a-z');
        if (resp.data) {
            this.productList = resp.data;
            this.pagination = {links: resp.links, meta: resp.meta}
        } else {
            this.productList = resp;
        }
        this.productResourceOffer.next(this.productList);
    }

    getProductsMasiva(products: any) {

        const user = this._companyService.user.id;
        const sucursal = this._companyService.user.sucursal;

        const data = {
            user,
            sucursal,
            products
        };

        const url = URL_SERVICES + 'get-products-by-sku';
        return this.http.post(url, data);
    }

    getProductsList(categorySlug?: string | null, sort?: string): Promise<any> {
        // const apiurl = categorySlug ? `products-by-category/${categorySlug}` : 'products-api'
        const sucursal = this._companyService.user.sucursal;
        const pageActual = this.router.url === '/promociones' ? this.sharedService.pageActualPromotions : this.sharedService.pageActual;
        const user = this._companyService.user.id;
        const apiurl = categorySlug ?
            `products-by-category/${categorySlug}/${sucursal}/${user}/${sort}` + `?page=${pageActual}`
            : 'get-products-sucursal/' + sucursal + '/' + sort + '/' + user + `?page=${pageActual}`

        // console.log(apiurl);
        return new Promise((resolve, reject) => {
            this.http.get(`${this.URL_API}${apiurl}`)
                .toPromise()
                .then((resp: any) => {
                    resolve(resp);
                })
                .catch(this.handleError)
        })
    }

    getProductFromUrl(url): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get(url)
                .toPromise()
                .then((resp: any) => {
                    resolve(resp)
                })
                .catch(this.handleError)
        })
    }

    getProductDetail(slug: string) {
        const user = this._companyService.user.id;
        return this.http.get(`${this.URL_API_PROD2}-show/${slug}/${user}`)
            .toPromise()
            .catch(this.handleError)

    }

    getRelatedProducts(productId: any): Observable<any> {
        const user = this._companyService.user.id;
        return this.http.get(`${URL_SERVICES}products-related/${productId}/${user}/${this._companyService.user.sucursal}`);
    }

    getDataRecentProducts() {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.URL_API}products-api`)
                .toPromise()
                .then((resp: any) => {
                    resolve(resp)
                })
                .catch(this.handleError)
        })
    }

    getDataPopularProducts() {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.URL_API}products-api`)
                .toPromise()
                .then((resp: any) => {
                    resolve(resp)
                })
                .catch(this.handleError)
        })
    }

    // Upload images Product
    public postFileImagen(formData, token: string) {

        let url = URL_SERVICES + 'product-pictures';
        url += '?token=' + token;

        return this.http.post(url, formData);
    }

    setFilterProducts(filters) {
        if (filters.range) {
            const newListProducts = this.productList.filter(product => product.price <= filters.range);
            this.productResource.next(newListProducts);
        }
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }

    async getProductsTradeFair(sort?, pageActual = 1) {
        const resp: any = await this.getProductsListTradeFair(sort ? sort : 'a-z', pageActual);
        if (resp.data) {
            this.productListTradeFair = resp.data;
            this.paginationTradeFair = {links: resp.links, meta: resp.meta}
        } else {
            this.productListTradeFair = resp;
        }
        this.productResourceTradeFair.next(this.productListTradeFair);
    }

    getProductsListTradeFair(sort: string, pageActual: number): Promise<any> {
        const sucursal = this._companyService.user.sucursal;
        const user = this._companyService.user.id;
        const apiurl = `products-by-trade-fair/${sucursal}/${user}/${sort}` + `?page=${pageActual}`;

        return new Promise((resolve, reject) => {
            this.http.get(`${this.URL_API}${apiurl}`)
                .toPromise()
                .then((resp: any) => {
                    resolve(resp);
                })
                .catch(this.handleError)
        })
    }

    async getNewProducts(sort?, pageActual = 1) {
        const resp: any = await this.getNewProductsList(sort ? sort : 'a-z', pageActual);
        if (resp.data) {
            this.newProductsList = resp.data;
            this.paginationNewProducts = {links: resp.links, meta: resp.meta}
        } else {
            this.newProductsList = resp;
        }
        this.newProductsResource.next(this.newProductsList);
    }

    getNewProductsList(sort: string, pageActual: number): Promise<any> {
        const sucursal = this._companyService.user.sucursal;
        const user = this._companyService.user.id;
        const apiurl = `new-products/${sucursal}/${user}/${sort}` + `?page=${pageActual}`;

        return new Promise((resolve, reject) => {
            this.http.get(`${this.URL_API}${apiurl}`)
                .toPromise()
                .then((resp: any) => {
                    resolve(resp);
                })
                .catch(this.handleError)
        })
    }

    getNotifications(pageActual = 1) {
        const user = this._companyService.user.id;
        let path = URL_SERVICES + 'notifications/{user}/product/all';
        path = path.replace('{user}', user);
        path += `?page=${pageActual}`;
        path += '&token=' + this._companyService.token;
        return this.http.get(path);
    }

    addNotification(product: Product) {
        const user = this._companyService.user.id;
        let path = URL_SERVICES + 'notification/{user}/{product}/add';
        path = path.replace('{user}', user);
        path = path.replace('{product}', product.id.toString());
        return this.http.post(path, {});
    }

    destroyNotification(product: Product) {
        const user = this._companyService.user.id;
        let path = URL_SERVICES + 'notification/{user}/{product}/destroy';
        path = path.replace('{user}', user);
        path = path.replace('{product}', product.id.toString());
        return this.http.post(path, {});
    }

    changeStatusNotification(notification) {
        let path = URL_SERVICES + 'notification/user/product/change/status/{notification}';
        path = path.replace('{notification}', notification);
        return this.http.put(path, []);
    }
}
