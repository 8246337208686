<!-- <app-block-features></app-block-features> -->

<!-- <app-block-products-carousel
    header="Productos"
    layout="grid-4"
    [loading]="featuredProducts.loading"
    [products]="featuredProducts.products"
    [groups]="featuredProducts.groups"
    (groupChange)="groupChange(featuredProducts, $event)"
></app-block-products-carousel> -->
<ng-container>
    <div class="container">
        <div class="shop-layout shop-layout--sidebar--start">
            <div class="shop-layout__sidebar">
                <app-shop-sidebar offcanvas="mobile"></app-shop-sidebar>
            </div>
            <div class="shop-layout__content mt-4">
                <div class="block">
                    <app-block-slideshow *ngIf="showBanner" [withDepartments]="false"></app-block-slideshow>
                    <app-product-view-alt *ngIf="!showBanner" layout="grid" grid="grid-3-sidebar" offcanvas="mobile"></app-product-view-alt>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- <ng-container>
    <div *ngIf="showBanner" class="container">

        <div class="block">
            <app-product-view-alt layout="grid" grid="grid-5-full" offcanvas="always"></app-product-view-alt>
        </div>
    </div>
</ng-container> -->

<!-- <app-block-banner></app-block-banner> -->

<!-- <app-block-products header="Bestsellers" layout="large-first" [products]="bestsellers$|async"></app-block-products>

<app-block-categories header="Popular Categories" layout="classic" [categories]="popularCategories$|async"></app-block-categories> -->

<!-- <app-block-products-carousel
    header="New Arrivals"
    layout="horizontal"
    [rows]="2"
    [loading]="latestProducts.loading"
    [products]="latestProducts.products"
    [groups]="latestProducts.groups"
    (groupChange)="groupChange(latestProducts, $event)"
></app-block-products-carousel> -->

<!-- <app-block-posts header="Latest News" layout="list-sm" [posts]="posts"></app-block-posts>

<app-block-brands [brands]="brands$|async"></app-block-brands>

<app-block-product-columns>
    <app-block-product-columns-item header="Top Rated Products" [products]="columnTopRated$|async"></app-block-product-columns-item>
    <app-block-product-columns-item header="Special Offers" [products]="columnSpecialOffers$|async"></app-block-product-columns-item>
    <app-block-product-columns-item header="Bestsellers" [products]="columnBestsellers$|async"></app-block-product-columns-item>
</app-block-product-columns> -->
