<div class="page-header mt-4">
    <div class="page-header__container container text-center pb-2">

        <div *ngIf="header" class="page-header__title" style="color: #0055AD !important;">
            <h1>{{ header }}</h1>
        </div>
        <!-- <div class="page-header__breadcrumb">
            <nav aria-label="breadcrumb" style="display: inline-block;">
                <ol class="breadcrumb">
                    <ng-container *ngFor="let breadcrumb of breadcrumbs; last as last">
                        <li *ngIf="!last" class="breadcrumb-item">
                            <a [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
                            <app-icon class="breadcrumb-arrow" name="arrow-rounded-right-6x9" size="6x9"></app-icon>
                        </li>
                        <li *ngIf="last" class="breadcrumb-item active" aria-current="page">{{ breadcrumb.label }}</li>
                    </ng-container>
                </ol>
            </nav>
        </div> -->
    </div>
</div>