<!-- <app-page-header [header]="'Checkout'" [breadcrumbs]="[
    {label: 'Inicio',          url: root.home()},
    {label: 'Carro de Compra', url: root.cart()},
    {label: 'Checkout',      url: ''}
]"></app-page-header> -->

<div class="checkout block" *ngIf="user">
    <div class="container">
        <div class="row">
            <!-- <div class="col-12 mb-3">
                <app-alert type="primary" size="lg">Soy Cliente? <a [routerLink]="root.login()">Click aqui para ingresar</a></app-alert>
            </div> -->
            <div class="col-12 col-lg-6 col-xl-7">
                <div class="card mb-lg-0">
                    <div class="card-body">
                        <h3 class="card-title">AGREGAR DIRECCIÓN</h3>
                        <div class="row mb-3">
                            <!-- <div class="col-md-12">
                                <h5>* Si posee una dirección predeterminada en su perfil, esta se tomara por defecto para el envio, o puede agregar una nueva.</h5>
                            </div> -->
                            <!-- <div class="payment-methods">
                                <ul class="payment-methods__list" *ngIf="addresses">
                                    <li class="payment-methods__item" *ngFor="let item of addresses">
                                        <label class="payment-methods__item-header">
                                        <span class="payment-methods__item-radio input-radio">
                                            <span class="input-radio__body">
                                                <input class="input-radio__input" id="item.id" (change)="shippingAddress(item)" name="checkout_payment_method" [checked]="item.checked" type="radio">
                                                <span class="input-radio__circle"></span>
                                            </span>
                                        </span>
                                        <span class="payment-methods__item-title">{{ item.address }}, {{ item.city }}, {{ item.state }}</span>
                                        </label>
                                    </li>
                                </ul>
                            </div> -->
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="checkout-last-name">Calle</label>
                                    <input type="text" class="form-control" id="checkout-last-name" name="street" [formControl]="checkoutForm.controls['street']" [(ngModel)]="user.street" placeholder="" readonly autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.street.touched && checkoutForm.controls.street.errors?.required" class="text text-danger">
                                        Calle es requerido.
                                    </div>
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="checkout-postcode">Colonia</label>
                                    <input type="text" class="form-control" name="colony" [formControl]="checkoutForm.controls['colony']" [(ngModel)]="user.colony" placeholder="" readonly autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.colony.touched && checkoutForm.controls.colony.errors?.required" class="text text-danger">
                                        Colonia es requerida
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="checkout-company-name">No. Exterior</label>
                                    <input type="text" class="form-control" name="exterior" [formControl]="checkoutForm.controls['exterior']" [(ngModel)]="user.nro_ext" placeholder="" readonly autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.exterior.touched && checkoutForm.controls.exterior.errors?.required" class="text text-danger">
                                        No Exterior es requerido.
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="checkout-company-name">No. Interior</label>
                                    <input type="text" class="form-control" name="interior" [formControl]="checkoutForm.controls['interior']" [(ngModel)]="user.nro_int" placeholder="" readonly autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.interior.touched && checkoutForm.controls.interior.errors?.required" class="text text-danger">
                                        No Interior es requerido.
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="checkout-postcode">Cod. Postal</label>
                                    <input type="text" class="form-control" name="postal" [formControl]="checkoutForm.controls['postal']" [(ngModel)]="user.postal" placeholder="" readonly autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.postal.touched && checkoutForm.controls.postal.errors?.required" class="text text-danger">
                                        Código postal es requerido
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="checkout-postcode">Ciudad</label>
                                    <input type="text" class="form-control" name="city" [formControl]="checkoutForm.controls['city']" [(ngModel)]="user.city" placeholder="" readonly autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.city.touched && checkoutForm.controls.city.errors?.required" class="text text-danger">
                                        Ciudad es requerido
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="checkout-postcode">Estado</label>
                                    <input type="text" class="form-control" name="state" [formControl]="checkoutForm.controls['state']" [(ngModel)]="user.state" placeholder="" readonly autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.state.touched && checkoutForm.controls.state.errors?.required" class="text text-danger">
                                        Estado es requerido
                                    </div>
                                </div>
                                <!-- <div class="form-group col-md-6">
                                    <label for="checkout-postcode">País</label>
                                    <input type="text" class="form-control" name="country" [formControl]="checkoutForm.controls['country']" value="" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.country.touched && checkoutForm.controls.country.errors?.required" class="text text-danger">
                                        País postal es requerido
                                    </div>
                                </div> -->

                                <div class="form-group col-md-6">
                                    <label for="sucursal">Sucursal</label>
                                    <select class="form-control" name="sucursal" [formControl]="checkoutForm.controls['sucursal']" [(ngModel)]="user.sucursal">
                                        <option *ngFor="let item of _sucursals" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="checkoutForm.controls.sucursal.touched && checkoutForm.controls.sucursal.errors?.required" class="text text-danger">
                                        Sucursal es requerida
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-group">
                                <label class="field-label">Estado</label>
                                <select [(ngModel)]="state" class="form-control" name="state" [formControl]="checkoutForm.controls['state']">
                                                <option value="CIUDAD DE MEXICO" selected>Ciudad de México</option>
                                                <option value="ESTADO DE MEXICO">Edo. de México</option>
                                                <option value="JALISCO">Jalisco</option>
                                                <option value="AGUASCALIENTES">Aguascalientes</option>
                                                <option value="BAJA CALIFORNIA">Baja California</option>
                                                <option value="BAJA CALIFORNIA SUR">Baja California Sur</option>
                                                <option value="CAMPECHE">Campeche</option>
                                                <option value="COAHUILA">Coahuila</option>
                                                <option value="COLIMA">Colima</option>
                                                <option value="CHIAPAS">Chiapas</option>
                                                <option value="CHIHUAHUA">Chihuahua</option>
                                                <option value="DURANGO">Durango</option>
                                                <option value="GUANAJUATO">Guanajuato</option>
                                                <option value="GUERRERO">Guerrero</option>
                                                <option value="HIDALGO">Hidalgo</option>
                                                <option value="MICHOACAN">Michoacán</option>
                                                <option value="MORELOS">Morelos</option>
                                                <option value="NAYARIT">Nayarit</option>
                                                <option value="NUEVO LEON">Nuevo León</option>
                                                <option value="OAXACA">Oaxaca</option>
                                                <option value="PUEBLA">Puebla</option>
                                                <option value="QUERETARO">Querétaro</option>
                                                <option value="QUINTANA ROO">Quintana Roo</option>
                                                <option value="SAN LUIS POTOSI">San Luis Potosí</option>
                                                <option value="SINALOA">Sinaloa</option>
                                                <option value="SONORA">Sonora</option>
                                                <option value="TABASCO">Tabasco</option>
                                                <option value="TAMAULIPAS">Tamaulipas</option>
                                                <option value="TLAXCALA">Tlaxcala</option>
                                                <option value="VERACRUZ">Veracruz</option>
                                                <option value="YUCATAN">Yucatán</option>
                                                <option value="ZACATECAS">Zacatecas</option>
                                            </select>
                                <div *ngIf="checkoutForm.controls.state.touched && checkoutForm.controls.state.errors?.required" class="text text-danger">
                                    Estado es requerido
                                </div>
                            </div> -->
                            <div class="col-md-12 mt-2 mb-3 text-right pull-right" *ngIf="user.shippingAddress.nombre !== ''">
                                <span class="font-weight-bold float-left pt-3"><a (click)="addNewAddress()" class="text-primary pointer">Solicitud de cambio de dirección</a> </span>
                                <button *ngIf="addresses.length === 0" class="btn btn-info pull-right btn-lg" (click)="openModalNewAddress()">Dirección nueva</button>
                                <button (click)="nextStepCheckout()" class="btn btn-next ml-3 btn-lg">Continuar</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-divider"></div>
                </div>
            </div>

            <div class="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                <div class="card mb-0">
                    <div class="card-body">
                        <!-- <h3 class="card-title">Tu orden</h3> -->

                        <table class="checkout__totals">
                            <!-- <thead class="checkout__totals-header">
                                <tr>
                                    <th></th>
                                    <th>Producto</th>
                                    <th>Total</th>
                                </tr>
                            </thead> -->
                            <tbody class="checkout__totals-products">
                                <tr *ngFor="let item of cart.items$|async">
                                    <!-- <td class="td-image"><img class="product-image__img" [src]="URL_SERVICES_IMG + item.product.id + '/' +item.product.coverImage" alt=""> </td> -->
                                    <td>{{ item.product.name }} × {{ item.quantity }}</td>
                                    <td>{{ item.product.price * item.quantity|currencyFormat }} MXN</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="(cart.totals$|async).length" class="checkout__totals-subtotals">
                                <tr>
                                    <th>Subtotal</th>
                                    <td>{{ cart.subtotal$|async|currencyFormat }} MXN</td>
                                </tr>
                                <!-- <tr *ngFor="let total of cart.totals$|async">
                                    <th>{{ total.title }}</th>
                                    <td>{{ total.price|currencyFormat }}</td>
                                </tr> -->
                            </tbody>
                            <tfoot class="checkout__totals-footer">
                                <tr>
                                    <th>Total</th>
                                    <td class="text-primary">{{ cart.total$|async|currencyFormat }} MXN</td>
                                </tr>
                            </tfoot>
                        </table>

                        <!-- <div class="payment-methods">
                            <ul class="payment-methods__list">
                                <li class="payment-methods__item payment-methods__item--active">
                                    <label class="payment-methods__item-header">
                                            <span class="payment-methods__item-radio input-radio">
                                                <span class="input-radio__body">
                                                    <input class="input-radio__input" name="checkout_payment_method" type="radio" checked>
                                                    <span class="input-radio__circle"></span>
                                                </span>
                                            </span>
                                        <span class="payment-methods__item-title">Transferencia bancaria directa</span>
                                    </label>
                                    <div class="payment-methods__item-container">
                                        <div class="payment-methods__item-description text-muted">
                                            Realice su pago directamente en nuestra cuenta bancaria. Utilice su ID de pedido como referencia de pago. Su pedido no se enviará hasta que los fondos se hayan liquidado en nuestra cuenta.
                                        </div>
                                    </div>
                                </li>
                                <li class="payment-methods__item">
                                    <label class="payment-methods__item-header">
                                            <span class="payment-methods__item-radio input-radio">
                                                <span class="input-radio__body">
                                                    <input class="input-radio__input" name="checkout_payment_method" type="radio">
                                                    <span class="input-radio__circle"></span>
                                                </span>
                                            </span>
                                        <span class="payment-methods__item-title">Verificar pagos</span>
                                    </label>
                                    <div class="payment-methods__item-container">
                                        <div class="payment-methods__item-description text-muted">
                                            Envíe un cheque a Nombre de la tienda, Calle de la tienda, Ciudad de la tienda, Estado / Condado de la tienda, Código postal de la tienda.
                                        </div>
                                    </div>
                                </li>
                                <li class="payment-methods__item">
                                    <label class="payment-methods__item-header">
                                            <span class="payment-methods__item-radio input-radio">
                                                <span class="input-radio__body">
                                                    <input class="input-radio__input" name="checkout_payment_method" type="radio">
                                                    <span class="input-radio__circle"></span>
                                                </span>
                                            </span>
                                        <span class="payment-methods__item-title">Contra reembolso</span>
                                    </label>
                                    <div class="payment-methods__item-container">
                                        <div class="payment-methods__item-description text-muted">
                                            Pague en efectivo a la entrega.
                                        </div>
                                    </div>
                                </li>
                                <li class="payment-methods__item">
                                    <label class="payment-methods__item-header">
                                            <span class="payment-methods__item-radio input-radio">
                                                <span class="input-radio__body">
                                                    <input class="input-radio__input" name="checkout_payment_method" type="radio">
                                                    <span class="input-radio__circle"></span>
                                                </span>
                                            </span>
                                        <span class="payment-methods__item-title">PayPal</span>
                                    </label>
                                    <div class="payment-methods__item-container">
                                        <div class="payment-methods__item-description text-muted">
                                            Pague a través de PayPal; puedes pagar con tu tarjeta de crédito si no tienes una cuenta de PayPal.
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div> -->

                        <!-- <div class="checkout__agree form-group">
                            <div class="form-check">
                                <span class="form-check-input input-check">
                                        <span class="input-check__body">
                                            <input class="input-check__input" type="checkbox" id="checkout-terms">
                                            <span class="input-check__box"></span>
                                <svg class="input-check__icon" width="9px" height="7px"><use xlink:href="assets/images/sprite.svg#check-9x7"></use></svg>
                                </span>
                                </span>
                                <label class="form-check-label" for="checkout-terms">
                                    He leído y acepto los <a target="_blank" [routerLink]="root.terms()">términos y condiciones del sitio web</a>*
                                </label>
                            </div>
                        </div>

                        <button type="submit" class="btn btn-primary btn-xl btn-block" (click)="createOrder()">Realizar pedido</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
