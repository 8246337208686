import {Component, OnInit, Input} from '@angular/core';
import {ProductService} from '../../services/products.service';
import {Layout} from '../products-view/products-view.component';
import {Subject} from 'rxjs';
import {ShopService} from 'src/app/shared/api/shop.service';
import {ShopSidebarService} from '../../services/shop-sidebar.service';

// import { PageCategoryService } from '../../services/page-category.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {BanerHomeService} from '../../services/baner-home.service';

@Component({
    selector: 'app-product-view-alt',
    templateUrl: './product-view-alt.component.html',
    styleUrls: ['./product-view-alt.component.scss']
})
export class ProductViewAltComponent implements OnInit {
    @Input() layout: Layout = 'grid';
    @Input() grid: 'grid-3-sidebar' | 'grid-4-full' | 'grid-5-full' = 'grid-3-sidebar';
    @Input() offcanvas: 'always' | 'mobile' = 'mobile';

    imageCategory;
    byCategory: boolean = false;
    pageActual: number = 1;
    pageSize: number = 10;
    oldSort = 'a-z';
    productList: any = [];
    header: 'Productos';
    columns: 4;
    viewMode: 'grid';
    categorySlug: 'power-tools';
    filtersCount: 0;
    order: string = 'a-z';
    pagination = {
        last_page: 1,
        current_page: 1,
        path: '',
        per_page: 1,
        to: 1,
        total: 1
    }

    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private _productsService: ProductService,
        private _shopService: ShopService,
        public sidebar: ShopSidebarService,
        public _bannerService: BanerHomeService,
        public sharedService: SharedService
    ) {
        this._productsService.productResource.subscribe(products => {
            if (products) {
                const productos: any = products;
                this.byCategory = products[0].by_category;
                this.imageCategory = products[0].categories[0] ? products[0].categories[0].image : '';
                productos.map((product) => {
                    product.priceWithIva = product.price + (product.price * product.iva);

                    return product;
                });

                this.productList = productos
                this.pagination = this._productsService.pagination.meta ? this._productsService.pagination.meta : {
                    last_page: 1,
                    current_page: 1,
                    path: ''
                };
            }
        })
    }

    ngOnInit(): void {
    }

    async getProductsData() {
        // this.productList = await this._shopService.getProductsLista();

        const products: any = await this._shopService.getProductsLista();
        // this.productSearch = await this._shopService.getProductsLista();
        products.map((product) => {
            product.priceWithIva = product.price + (product.price * product.iva);

            return product;
        });

        this.productList = products

    }

    // sorting type ASC / DESC / A-Z / Z-A etc.
    public onChangeSorting(val) {
        this.sharedService.pageActual = 1;
        const long = this.oldSort.length;
        this.oldSort = val;
        const pagination = this.pagination.path.slice(0, -long);
        const url = pagination + val + `?page=${this.sharedService.pageActual}`;

        if (this._bannerService.showCategory) {
            // const url = URL_SERVICES + 'products-by-category/promociones/1/'+this._companyService.user.id+'/'+val+`?page=${this.sharedService.pageActualPromotions}`

            this._productsService.getProducts(null, url, val)
        } else {
            this._productsService.getProducts(null, null, val)
        }
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    newPage(event) {
        this.sharedService.pageActual = event;
        const url = this.pagination.path + `?page=${event}`
        this._productsService.getProducts(null, url, this.order)
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    setLayout(value: Layout): void {
        this.layout = value;
    }

    resetFilters(): void {
        // this.pageService.updateOptions({filterValues: {}});
    }

}
