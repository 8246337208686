import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CompanyService } from 'src/app/shared/services/company.service';

@Component({
  selector: 'app-modal-checkout',
  templateUrl: './modal-checkout.component.html',
  styleUrls: ['./modal-checkout.component.scss']
})
export class ModalCheckoutComponent implements OnInit {
  closeBtnName: string;
  continueBtnName: string;
  modal = '';

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    public _companyService: CompanyService,
    public router: Router,
  ) { 
    this.modalService.config.backdrop = true;
  }

  ngOnInit(): void {
    this.modal = this._companyService.user.modal;
  }

  closeModal(type: string) {

      if(type === 'shop'){
          this.router.navigate(['/']);
          this.bsModalRef.hide();
      } else if(type === 'close'){
        // this.router.navigate(['/carrito/checkout/exito']);
          this.bsModalRef.hide();
      }
  }

}
