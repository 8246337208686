import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-term',
  templateUrl: './modal-term.component.html',
  styleUrls: ['./modal-term.component.scss']
})
export class ModalTermComponent implements OnInit {

  closeBtnName: string;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

}
