import { Component, EventEmitter, Output } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { RootService } from '../../../../shared/services/root.service';

@Component({
    selector: 'app-header-dropnotification',
    templateUrl: './dropnotification.component.html',
    styleUrls: ['./dropnotification.component.scss']
})
export class DropNotificationComponent {

    @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        public root: RootService,
        public notification: NotificationService
    ) { }
}