import { Pipe, PipeTransform } from '@angular/core';
import { URL_SERVICES_IMAGES } from '../config/config';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  transform( img: string, tipo: string = 'user', id): any {

    let url = URL_SERVICES_IMAGES;

    if ( img === 'no-photo.png' ) {
      return url + 'products/no-photo.png';
    }

    if (img.startsWith('http://') || img.startsWith('https://')) {
        console.log('Estro al http condition')
        return img;
      }

    switch (tipo) {

      case 'user':
        url += 'user/' + id + '/' + img;
      break;

      case 'products':
        url += 'products/' + id + '/' + img;
      break;

      case 'config':
        url += 'config/' + img;
      break;

      default:
        url += 'no-foto.jpg';
    }

    return url;
  }

}
