<div class="site-header">
    <app-header-topbar></app-header-topbar>

    <div *ngIf="layout === 'classic'" class="site-header__middle container">
        <div class="site-header__logo" style="height: 100%;">
            <a routerLink="/" style="width: 100%; height: 100%;">
                <img src="assets/brand/logo-dark.png" style="height: 100%;" alt="logo-farmater">
            </a>
        </div>
        <div class="site-header__search">
            <app-search location="header"></app-search>
        </div>

        <!-- <app-icon class="ml-3 color-blue font-weight-bold" name="person-20" size="20"></app-icon> -->
        <div class="site-header__phone ml-2 mr-3 color-green font-weight-bold">
            <!-- <div class="text-left text-uppercase" *ngIf="_companyService.isLogin()">Hola</div> -->
            <div class="site-header__phone-title" *ngIf="!_companyService.isLogin()"><a style="color: #fff !important;" routerLink="/login"> Login </a>| <a style="color: #fff !important;" routerLink="/login">Registrarse</a></div>
            <div class="site-header__phone-title" style="color: #fff !important;" *ngIf="_companyService.isLoginCompany()">{{ _companyService.user.profile.name_company }}</div>
            <div class="site-header__phone-title" style="color: #fff !important;" *ngIf="_companyService.isLoginClient()" routerLink="/cuenta">{{ _companyService.user.profile.name }} {{ _companyService.user.profile.lastname }}</div>
            <!-- <div class="site-header__phone-number">{{ store.primaryPhone }}</div> -->
        </div>
        <div class="account-menu__user-avatar cursor" routerLink="/cuenta">
            <img src="./assets/images/avatars/avatar-3.jpg" *ngIf="!_companyService.user.image">
            <img width="50px" height="50px" style="max-width:50px; max-height: 50px;" [src]="_companyService.user.image | image:'user': _companyService.user.id" *ngIf="_companyService.user.image">
        </div>
        <!-- <app-indicator icon="cart-20" [counter]="cart.quantity$|async">
        </app-indicator> -->
    </div>

    <div class="site-header__nav-panel">
        <app-header-nav [departments]="layout === 'classic'" [logo]="layout === 'compact'" [search]="layout === 'compact'" stickyMode="pullToShow"></app-header-nav>
    </div>
</div>
