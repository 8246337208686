import { Component, Input } from '@angular/core';
import { StoreService } from '../../shared/services/store.service';
import { CartService } from '../../shared/services/cart.service';
import { ShopService } from 'src/app/shared/api/shop.service';
import { CompanyService } from '../../shared/services/company.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    @Input() layout: 'classic'|'compact' = 'classic';
    categories: any;

    constructor(
        public store: StoreService,
        public _companyService: CompanyService,
        public cart: CartService,
        private _shopService: ShopService
    ) {


        this.getCategoryData();
    }

    async getCategoryData() {
        this.categories = await this._shopService.getCategories();
    }
}
