<app-page-header [header]="'Notificaciones'" [breadcrumbs]="[
    {label: 'Inicio',      url: '/'},
    {label: 'Notificaciones', url: ''}
]"></app-page-header>

<div class="container">
    <div class="products-view__options mt-3">
        <div class="view-options" [ngClass]="{
                        'view-options--offcanvas--always': offcanvas === 'always',
                        'view-options--offcanvas--mobile': offcanvas === 'mobile'
                    }">
            <div class="view-options__legend">
                Viendo {{ this.pagination.per_page > this.pagination.total ? this.pagination.to : this.pagination.per_page }}–{{ this.pagination.to }} de {{ this.pagination.total }} resultados
            </div>
            <div class="view-options__divider"></div>
            <div class="view-options__control"></div>
        </div>
    </div>

    <table class="wishlist">
        <thead class="wishlist__head">
        <tr class="wishlist__row">
            <th width="20%" class="wishlist__column">Imagen</th>
            <th width="30%" class="wishlist__column">Titulo</th>
            <th class="wishlist__column">Mensaje</th>
        </tr>
        </thead>
        <tbody class="wishlist__body">
        <tr *ngFor="let notification of notifications | paginate: { itemsPerPage: 10, currentPage: sharedService.pageActualNotification, totalItems: this.pagination.total }"
            class="wishlist__row">
            <td class="wishlist__column wishlist__column--image">
                <div class="product-image">
                    <a href="{{notification.url}}" class="product-image__body">
                        <img class="product-image__img" [src]="notification.image" alt="">
                    </a>
                </div>
            </td>
            <td class="wishlist__column">
                <a class="wishlist__product-name">{{ notification.title }}</a>
            </td>
            <td class="wishlist__column">
                <a class="wishlist__product-name">{{ notification.message }}</a>
            </td>
        </tr>
        </tbody>
    </table>
    <div class="products-view__pagination">
        <pagination-controls class="rounded text-center rounded-circle" responsive="true" previousLabel="" nextLabel="" (pageChange)="newPage($event)"></pagination-controls>
    </div>
</div>
