<ng-container>
    <div class="container">
        <div class="shop-layout shop-layout--sidebar--start">
            <div class="shop-layout__sidebar">
                <div class="block block- mt-5" [ngClass]="{
                        'block-sidebar--offcanvas--always': offcanvas === 'always',
                        'block-sidebar--offcanvas--mobile': offcanvas === 'mobile'
                    }">
                    <div class="block-sidebar__body">
                        <div class="block-sidebar__item" id="filters">
                            <div class="widget-filters widget-filters-advanced widget" id="substances" [ngClass]="{
                                            'widget-filters--offcanvas--always': offcanvas === 'always',
                                            'widget-filters--offcanvas--mobile': offcanvas === 'mobile'
                                        }" appCollapse>
                                <h4 class="widget-filters__title" (click)="isHiddenSub = !isHiddenSub">
                                    Sustancias
                                    <img [ngClass]="!isHiddenSub ? 'img_down' : 'img_up'" align="right" width="20" src="assets/images/svg/up-arrow.svg">
                                </h4>
                                <div class="widget-filters__list" [hidden]="isHiddenSub">
                                    <div class="widget-filters__item widget-filters-item-advanced">
                                        <div class="advanced_search">
                                            <input class="advanced_search_input" type="text" placeholder="Buscar Sustancia" (input)="onChangeSubstance($event)">
                                            <app-icon class="advanced_search_image" name="search-20" size="20"></app-icon>
                                        </div>
                                        <ul class="filter-categories__list filter-categories-advanced">
                                            <li *ngFor="let item of substances" class="filter-categories__item filter-categories-item-advanced filter-categories__item--categories">
                                                <label class="widget-filters__title">
                                                    <input type="checkbox" id="{{ item.description }}" name="{{ item.description }}" value="{{ item.description }}" (click)="onFilters($event, 'Substance')">
                                                    <a style="padding-left: 5px; font-size: 12px !important;">{{ item.description }}</a>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="widget-filters widget-filters-advanced widget" id="labs" [ngClass]="{
                                            'widget-filters--offcanvas--always': offcanvas === 'always',
                                            'widget-filters--offcanvas--mobile': offcanvas === 'mobile'
                                        }" appCollapse>
                                <h4 class="widget-filters__title" (click)="isHiddenLab = !isHiddenLab">
                                    Laboratorios
                                    <img [ngClass]="!isHiddenLab ? 'img_down' : 'img_up'" align="right" width="20" src="assets/images/svg/up-arrow.svg">
                                </h4>
                                <div class="widget-filters__list" [hidden]="isHiddenLab">
                                    <div class="widget-filters__item widget-filters-item-advanced">
                                        <div class="advanced_search">
                                            <input class="advanced_search_input" type="text" placeholder="Buscar Laboratorio" (input)="onChangeLab($event)">
                                            <app-icon class="advanced_search_image" name="search-20" size="20"></app-icon>
                                        </div>
                                        <ul class="filter-categories__list filter-categories-advanced">
                                            <li *ngFor="let item2 of labs" class="filter-categories__item filter-categories-item-advanced filter-categories__item--categories">
                                                <label class="widget-filters__title">
                                                    <input type="checkbox" id="{{ item2.id }}" name="{{ item2.name }}" value="{{ item2.id }}" (click)="onFilters($event, 'Lab')">
                                                    <a style="padding-left: 5px; font-size: 12px !important;">{{ item2.name }}</a>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="widget-filters widget-filters-advanced widget" id="presentations" [ngClass]="{
                                            'widget-filters--offcanvas--always': offcanvas === 'always',
                                            'widget-filters--offcanvas--mobile': offcanvas === 'mobile'
                                        }" appCollapse>
                                <h4 class="widget-filters__title" (click)="isHiddenPres = !isHiddenPres">
                                    Presentaciones
                                    <img [ngClass]="!isHiddenPres ? 'img_down' : 'img_up'" align="right" width="20" src="assets/images/svg/up-arrow.svg">
                                </h4>
                                <div class="widget-filters__list" [hidden]="isHiddenPres">
                                    <div class="widget-filters__item widget-filters-item-advanced">
                                        <div class="advanced_search">
                                            <input class="advanced_search_input" type="text" placeholder="Buscar Presentación" (input)="onChangePresentation($event)">
                                            <app-icon class="advanced_search_image" name="search-20" size="20"></app-icon>
                                        </div>
                                        <ul class="filter-categories__list filter-categories-advanced">
                                            <li *ngFor="let item3 of presentations" class="filter-categories__item filter-categories-item-advanced filter-categories__item--categories">
                                                <label class="widget-filters__title">
                                                    <input type="checkbox" id="{{ item3.description }}" name="{{ item3.description }}" value="{{ item3.description }}" (click)="onFilters($event, 'Presentation')">
                                                    <a style="padding-left: 5px; font-size: 12px !important;">{{ item3.description }}</a>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="shop-layout__content mt-4">
                <div class="block">
                    <div class="products-view">
                        <div class="block">
                            <div class="products-view__content" *ngIf="showProduct && productList.data.length > 0">
                                <div class="col-12 labs-carousel" *ngIf="this._nameLab !== false">
                                    <h6 class="prominent">MÁS DESTACADOS</h6>
                                    <h6 class="name_lab" [ngStyle]="{'width': _nameLabLength + 'px'}">{{ _nameLab | uppercase }}</h6>
                                    <div class="block-products-carousel" [ngClass]="{
                                            'block-products-carousel--has-items': !!products?.length
                                        }" [attr.data-layout]="layout">
                                        <div [ngClass]="{'container': true}">
                                            <div class="block-products-carousel__slider">
                                                <div class="block-products-carousel__preloader"></div>
                                                <owl-carousel-o [options]="customOptions" appOwlPreventClick>
                                                    <ng-template *ngFor="let product of products" carouselSlide>
                                                        <div class="block-products-carousel__column">
                                                            <div class="block-products-carousel__cell">
                                                                <app-product-prominent [product]="product"></app-product-prominent>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </owl-carousel-o>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="products-view__options mt-3">
                                    <div class="view-options" [ngClass]="{
                                        'view-options--offcanvas--always': offcanvas === 'always',
                                        'view-options--offcanvas--mobile': offcanvas === 'mobile'
                                    }">
                                        <div class="view-options__layout">
                                            <div class="layout-switcher">
                                                <div class="layout-switcher__list">
                                                    <button title="Grid" type="button" class="layout-switcher__button"
                                                            [ngClass]="{'layout-switcher__button--active': layout === 'grid'}"
                                                            (click)="setLayout('grid')">
                                                        <app-icon name="layout-grid-16x16" size="16"></app-icon>
                                                    </button>
                                                    <button title="List" type="button" class="layout-switcher__button"
                                                            [ngClass]="{'layout-switcher__button--active': layout === 'list'}"
                                                            (click)="setLayout('list')">
                                                        <app-icon name="layout-list-16x16" size="16"></app-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-options__legend">
                                            Viendo {{ this.pagination.per_page > this.pagination.total ? this.pagination.to : this.pagination.per_page }}–{{ this.pagination.to }} de {{ this.pagination.total }} resultados
                                        </div>
                                        <div class="view-options__divider" style="text-align: center">
                                            <button *ngIf="this.sendFilters.substances.length > 0 || this.sendFilters.labs.length > 0 || this.sendFilters.presentations.length > 0"
                                                    type="button" class="btn btn-primary btn-sm" (click)="onResetFilters()">Restablecer filtros</button>
                                        </div>
                                        <div class="view-options__control">
                                            <label for="view-options-sort">Ordenar por</label>
                                            <div>
                                                <select [(ngModel)]="order" class="form-control form-control-sm" id="view-options-sort" (change)="onChangeSorting($event.target.value)">
                                                    <option value="a-z">Nombre (A-Z)</option>
                                                    <option value="z-a">Nombre (Z-A)</option>
                                                    <option value="price-a">Precio ASC</option>
                                                    <option value="price-z">Precio DESC</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="products-view__list products-list"
                                     [attr.data-layout]="layout != 'list' ? grid : layout"
                                     [attr.data-with-features]="layout == 'grid-with-features' ? 'true' : 'false'">
                                    <div class="products-list__body">
                                        <div *ngFor="let product of productList.data | paginate: { itemsPerPage: 21, currentPage: sharedService.pageActualAdvancedSearch, totalItems: this.pagination.total }"
                                            class="products-list__item">
                                            <app-product-card [product]="product" [order]="order" [pieces]="product.pieces"></app-product-card>
                                        </div>
                                    </div>
                                    <div class="products-view__pagination">
                                        <pagination-controls class="rounded text-center rounded-circle" responsive="true" previousLabel="" nextLabel="" (pageChange)="newPage($event)">
                                        </pagination-controls>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!showProduct" class="products-view__empty">
                                <div class="products-view__empty-title">No hay elementos coincidentes.</div>
                                <div class="products-view__empty-subtitle">Intenta restablecer los filtros</div>
                                <button type="button" class="btn btn-primary btn-sm" (click)="onResetFilters()">Restablecer filtros</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
