import {Component, OnDestroy, OnInit} from '@angular/core';
import {posts} from '../../../data/blog-posts';
import {Brand} from '../../shared/interfaces/brand';
import {Observable, Subject, merge} from 'rxjs';
import {ShopService} from '../../shared/api/shop.service';
import {Product} from '../../shared/interfaces/product';
import {Category} from '../../shared/interfaces/category';
import {BlockHeaderGroup} from '../../shared/interfaces/block-header-group';
import {takeUntil, tap} from 'rxjs/operators';
import {CategoryService} from 'src/app/modules/shop/services/category.service';
import {ProductService} from 'src/app/modules/shop/services/products.service';
import {BanerHomeService} from 'src/app/modules/shop/services/baner-home.service';
import {CartService} from '../../shared/services/cart.service';
import {WishlistService} from '../../shared/services/wishlist.service';
import {Router} from '@angular/router';
import {SharedService} from '../../shared/services/shared.service';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {ModalCheckoutComponent} from '../../modules/shop/components/modal-checkout/modal-checkout.component';
import {NotificationService} from '../../shared/services/notification.service';

interface ProductsCarouselGroup extends BlockHeaderGroup {
    products$: Observable<Product[]>;
}

interface ProductsCarouselData {
    abort$: Subject<void>;
    loading: boolean;
    products: Product[];
    groups: ProductsCarouselGroup[];
}

@Component({
    selector: 'app-home',
    templateUrl: './page-home-one.component.html',
    styleUrls: ['./page-home-one.component.scss']
})
export class PageHomeOneComponent implements OnInit, OnDestroy {
    destroy$: Subject<void> = new Subject<void>();
    public bsModalRef: BsModalRef;
    bestsellers$: Observable<Product[]>;
    brands$: Observable<Brand[]>;
    popularCategories$: any;
    categories: any[];
    showBanner = true;

    columnTopRated$: Observable<Product[]>;
    columnSpecialOffers$: Observable<Product[]>;
    columnBestsellers$: Observable<Product[]>;
    viewMode: 'grid' | 'grid-with-features' | 'list' = 'grid';

    posts = posts;

    featuredProducts: ProductsCarouselData;
    latestProducts: any;
    loadingRecent: boolean = true;
    loadingPopular: boolean = true;

    constructor(
        private shop: ShopService,
        private _categoryService: CategoryService,
        private _productService: ProductService,
        public _bannerHomeService: BanerHomeService,
        private _carService: CartService,
        private _notificationService: NotificationService,
        private modalService: BsModalService,
        private _wishlistService: WishlistService,
        private router: Router,
        public sharedService: SharedService
    ) {

        const url = this.router.url;
        if (url.indexOf('products') >= 0) {
            this.showBanner = false;
        } else {
            this.showBanner = true;
        }

        // Antes estaba asi, path: 'products' en el routing principal, antes era inicio
        // if(url.indexOf('inicio') >= 0){
        //     this.showBanner = true;
        // }else{
        //     this.showBanner = false;
        // }
        // this.openModal();
        this._bannerHomeService.show();
        this._productService.getProducts();
        this._wishlistService.load();

    }

    ngOnInit(): void {
        this._carService.load();
        this._notificationService.load();
        // this.sharedService.resetTimer();
        this._bannerHomeService.showBanner$.subscribe(resp => {
            this.showBanner = resp;
        });
        this._categoryService.categoryResource.subscribe(resp => {
            this.popularCategories$ = resp
        });
        this._productService.recentProductsResource.subscribe(resp => {
            if (!this.loadingRecent) {
                this.loadingRecent = true;
            }
            this.latestProducts = resp;
            this.loadingRecent = false;
        });
        this._productService.popularProductsResource.subscribe(resp => {
            if (!this.loadingPopular) {
                this.loadingPopular = true;
            }
            this.featuredProducts = resp;
            this.loadingPopular = false;
        })
        this.getCategories();

        this.featuredProducts = {
            abort$: new Subject<void>(),
            loading: false,
            products: [],
            groups: [
                {
                    name: '',
                    current: false,
                    products$: this.shop.getFeaturedProducts(null, 8),
                },
                {
                    name: 'Power Tools',
                    current: false,
                    products$: this.shop.getFeaturedProducts('power-tools', 8),
                },
                {
                    name: 'Hand Tools',
                    current: false,
                    products$: this.shop.getFeaturedProducts('hand-tools', 8),
                },
                {
                    name: 'Plumbing',
                    current: false,
                    products$: this.shop.getFeaturedProducts('plumbing', 8),
                },
            ],
        };
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this._categoryService.categoryResource.unsubscribe();
        this._productService.recentProductsResource.unsubscribe();
        this._productService.popularProductsResource.unsubscribe()
    }

    async getCategories() {
        this.shop.getCategories().subscribe(resp => {
            this.categories = resp
        })

    }

    groupChange(carousel: ProductsCarouselData, group: BlockHeaderGroup): void {
        carousel.loading = true;
        carousel.abort$.next();

        (group as ProductsCarouselGroup).products$.pipe(
            tap(() => carousel.loading = false),
            takeUntil(merge(this.destroy$, carousel.abort$)),
        ).subscribe(x => carousel.products = x);
    }
}
