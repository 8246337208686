import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ShopSidebarService } from '../../services/shop-sidebar.service';
import { PageCategoryService } from '../../services/page-category.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ShopService } from 'src/app/shared/api/shop.service';
import { ProductService } from '../../services/products.service';

export type Layout = 'grid'|'grid-with-features'|'list';

@Component({
    selector: 'app-products-view',
    templateUrl: './products-view.component.html',
    styleUrls: ['./products-view.component.scss']
})
export class ProductsViewComponent implements OnInit, OnDestroy {
    @Input() layout: Layout = 'grid';
    @Input() grid: 'grid-3-sidebar'|'grid-4-full'|'grid-5-full' = 'grid-3-sidebar';
    @Input() offcanvas: 'always'|'mobile' = 'mobile';

    pageActual: number = 1;
    pageSize: number = 10;

    destroy$: Subject<void> = new Subject<void>();

    listOptionsForm: FormGroup;
    filtersCount = 0;

    productList: any[] = [];
    pagination = {
        last_page: 1,
        current_page:1,
        path: '',
        per_page:1,
        to: 1,
        total:1
    }

    constructor(
        private fb: FormBuilder,
        public sidebar: ShopSidebarService,
        public pageService: PageCategoryService,
        private _shopService: ShopService,
        private _productsService: ProductService
    ) {
        this._productsService.productResource.subscribe(products => {            
            if(products) {
                this.productList = products;
                this.pagination = this._productsService.pagination.meta ? this._productsService.pagination.meta : { last_page: 1, current_page:1, path:'' }
            }
            
        })
    }

    ngOnInit(): void {
        this.getProductsData();
        this.listOptionsForm = this.fb.group({
            page: this.fb.control(this.pageService.page),
            limit: this.fb.control(this.pageService.limit),
            sort: this.fb.control(this.pageService.sort),
        });
        this.listOptionsForm.valueChanges.subscribe(value => {
            value.limit = parseFloat(value.limit);

            this.pageService.updateOptions(value);
        });

        this.pageService.list$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(
            ({page, limit, sort, filterValues}) => {
                this.filtersCount = Object.keys(filterValues).length;
                this.listOptionsForm.setValue({page, limit, sort}, {emitEvent: false});
            }
        );
    }
    async getProductsData() {
        // this.productList = await this._shopService.getProductsLista();    
        
        const productsOffer: any = await this._shopService.getProductsLista();
        productsOffer.map( (product) => {
          product.priceWithIva = product.price + (product.price * product.iva);
    
          return product;
        });
    
        this.productList = productsOffer;
    }

    
    newPage(event) {
        const url = this.pagination.path + `?page=${event}`
        this._productsService.getProducts(null, url)
        
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    setLayout(value: Layout): void {
        this.layout = value;
    }

    resetFilters(): void {
        this.pageService.updateOptions({filterValues: {}});
    }
}
