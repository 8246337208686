<!-- <div class="modal-header">
    <button type="button" class="close pull-right d-none" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">X&times;</span>
    </button>
</div> -->
<div class="modal-body text-center font-weight-bold">
    <!-- <h3>Se le envió un correo de confirmación de compra a su correo.</h3> -->
    <img [src]="modal" class="text-center img-responsive fit-image2" alt="checkout1">
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="closeModal('close')">{{closeBtnName}}</button>
    <!-- <button type="button" class="btn btn-success" (click)="closeModal('shop')">{{continueBtnName}}</button> -->
</div>