import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BanerHomeService {
  private showBannerSubject$: Subject<boolean> = new Subject();

  showBanner$: Observable<boolean> = this.showBannerSubject$.asObservable();
  showCategory = false;

  constructor() { }
  show(): void {
    this.showBannerSubject$.next(true);
    this.showCategory = false;
  }

  close(): void {
      this.showBannerSubject$.next(false);
      this.showCategory = true;
  }
}
