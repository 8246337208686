import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {URL_SERVICES} from '../../config/config';
import {Router} from '@angular/router';
import {throwError} from 'rxjs';
import {map, filter, scan} from 'rxjs/operators';
import {CartService} from './cart.service';
import {SharedService} from './shared.service';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {ModalCheckoutComponent} from '../../modules/shop/components/modal-checkout/modal-checkout.component';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    public user;
    public token;
    public bsModalRef: BsModalRef;

    constructor(
        private http: HttpClient,
        private modalService: BsModalService,
        private toastr: ToastrService,
        public router: Router
    ) {
        this.loadStorage();
    }

    // Load user information in localstorage
    loadStorage() {

        if (localStorage.getItem('token')) {
            this.token = localStorage.getItem('token');
            this.user = JSON.parse(localStorage.getItem('user'));
        } else {
            this.token = '';
            this.user = null;
        }

    }

    // Create company
    createCompany(company, role) {

        const url = URL_SERVICES + 'users';

        company.role = role;

        return this.http.post(url, company)
    }

    isLoginCompany() {

        this.loadStorage();
        return (this.token.length > 5 && this.user.role === 'Empresa') ? true : false;
    }

    isLoginClient() {

        this.loadStorage();
        return (this.token.length > 5 && this.user.role === 'Cliente') ? true : false;
    }

    loadUser() {
        let url = URL_SERVICES + 'users/' + this.user.id;
        url += '?token=' + this.token;

        return this.http.get(url);
    }

    getUser() {

        if (!this.user) {
            this.router.navigate(['/cuenta/login']);
        }
        let url = URL_SERVICES + 'users/' + this.user.id;
        url += '?token=' + this.token;

        return this.http.get(url);
    }

    sendAddress(data) {
        const url = URL_SERVICES + 'change-address/' + this.user.id;

        return this.http.post(url, data);
    }

    isLogin() {

        this.loadStorage();
        return (this.token.length > 5) ? true : false;
    }

    // login
    login(user) {

        const url = URL_SERVICES + 'ingresar';

        return this.http.post(url, user).subscribe((resp: any) => {
            const response = resp;
            if (resp.user.role === 'Cliente') {
                this.saveStorage(response.user.id, response.access_token, response.user);
                this.user = response.user;
                // this.cart.load()
                this.openModal();
                this.router.navigate(['/']);
                return true;
            } else {
                this.toastr.error(`No posee permisos para ingresar aqui`);
                return;
            }
            // if( response.user.role === 'Empresa') {
            //   this.router.navigate(['/cuenta/dashboard']);
            //   return true;
            // }else if( response.user.role === 'Cliente') {
            //   this.router.navigate(['/cuenta/dashboard']);
            //   return true;
            // }
        }, (error: any) => {
            this.toastr.error(`Correo o contraseña invalida`);
        });
    }

    openModal() {
        this.bsModalRef = this.modalService.show(ModalCheckoutComponent, Object.assign({}, {class: 'modal-lg'}, {
            keyboard: false, ignoreBackdropClick: true
        }));
        // this.sharedService.modalPrincipal = false;
        this.bsModalRef.content.closeBtnName = 'Cerrar';
        this.bsModalRef.content.continueBtnName = 'Seguir Comprando';
    }

    // Logout
    logout() {
        this.user = null;
        this.token = '';

        localStorage.removeItem('token');
        localStorage.removeItem('user');

        const url = URL_SERVICES + 'logout';
        this.http.get(url);
        this.toastr.success(`Se cerró la sesión`);
        this.router.navigate(['/login']);
    }

    // Save user information in localstorage
    saveStorage(id, token: string, user) {

        localStorage.setItem('id', id);
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));

        this.token = token;
    }

    updateUser(data) {

        let url = URL_SERVICES + 'users/' + this.user.id;
        url += '?token=' + this.token;

        return this.http.put(url, data).subscribe((response: any) => {
            this.saveStorage(response.id, this.token, response);
            this.toastr.success('El usuario se actualizo correctamente');
            // this.user = response.user;
            return response;

        }, (error) => {
            this.toastr.error(error.error.error);
        });
    }

    // Save image profile
    saveImageProfile(formData) {

        let url = URL_SERVICES + 'users/picture-profile/' + this.user.id;
        url += '?token=' + this.token;

        return this.http.post(url, formData).subscribe((resp: any) => {
            const response = resp;
            this.toastr.success(`La imagen se actualizo correctamente`);
            this.saveStorage(response.id, this.token, response);
        }, (error) => {
            this.toastr.error(`Error subiendo imagen`);
        });
    }

    recoverPass(user) {

        const url = URL_SERVICES + 'reset-password';
        // url += '?token=' + this.token;

        return this.http.post(url, user)
            .subscribe((resp: any) => {
                this.toastr.success(`Se ha enviado un correo a su bandeja de entrada para restablecer su contraseña`);
            }, (error) => {
                this.toastr.error(`Error recuperando contraseña`);
            });

    }

    resetPassword(formReset) {

        const url = URL_SERVICES + 'password/reset';

        return this.http.post(url, formReset)
            .subscribe((resp: any) => {
                this.toastr.success(`La contraseña se restauró con exito`);
            }, (error) => {
                this.toastr.error(`No se ha podido restaurar la contraseña`);
            });
    }

    getAchievements() {
        const user = this.user.id;
        let url = URL_SERVICES + 'achievements-by-users/' + user;
        url += '?token=' + this.token;
        return this.http.get(url);
    }
}
